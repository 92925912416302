<template>
  <div>
    <form>
      <main class="cf">
        <div class="container">
          <section class="common_section basic_info">
            <h2>基本情報確認</h2>
            <div class="box">
              <dl class="info_detail">
                <dt v-if="isCorp" id="corp_name_row">法人名</dt>
                <dd v-if="isCorp" id="corp_name">{{ corpNameView }}</dd>
                <dt v-if="isCorp" id="belong_dept_name_row">部署名</dt>
                <dd v-if="isCorp" id="belong_dept_name">{{ belongDeptNameView }}</dd>
                <dt>氏名</dt>
                <dd id="full_name">{{ nameView }}</dd>
                <dt>フリガナ</dt>
                <dd id="kana_name">{{ kanaNameView }}</dd>
                <dt>性別</dt>
                <dd id="gender">{{ genderView }}</dd>
                <dt>生年月日</dt>
                <dd id="birthday">{{ birthDayView }}</dd>
                <dt class="maxw">運転免許証情報</dt>
                <dd>
                  <dl class="info_detail_sub">
                    <dt>有効期限</dt>
                    <dd id="license_expiration">{{ licenseExpirationView }}</dd>
                    <dt>免許証番号</dt>
                    <dd id="licence_no">{{ licenceNoView }}</dd>
                    <dt>免許証種別</dt>
                    <dd id="licence_type">{{ licenseTypeName }}</dd>
                    <dt>免許証写真</dt>
                    <dd>
                      <div class="upload_files">
                        <div class="upload_file_name">表</div>
                        <div class="upload_file_box">
                          <div class="file_disp">
                            <img
                              class="file_preview"
                              :src="uploadedFrontImageView"
                              alt="License surface"
                            />
                          </div>
                        </div>
                        <div class="upload_file_name">裏</div>
                        <div class="upload_file_box">
                          <div class="file_disp">
                            <img
                              class="file_preview"
                              :src="uploadBackImageView"
                              alt="License back"
                            />
                          </div>
                        </div>
                      </div>
                    </dd>
                  </dl>
                </dd>
                <dt>運転免許証住所</dt>
                <dd id="license_address">
                  〒{{ licenseZipCodeView }}
                  <br />
                  {{ licenseAddressView }}{{ licenseAddressOtherView }}
                </dd>
                <div v-show="isAnotherAddress">
                  <dt id="current_address_row" class="maxw">
                    運転免許証記載の住所と現住所が異なる場合
                  </dt>
                  <dd>
                    <dl class="info_detail_sub">
                      <dt>現住所</dt>
                      <dd id="current_address">
                        〒{{ currentZipCodeView }}
                        <br />
                        {{ currentAddressView }}{{ currentAddressOtherView }}
                      </dd>
                      <dt>現住所が確認できる書類</dt>
                      <dd>
                        <div class="file_disp">
                          <img
                            class="file_preview"
                            :src="uploadCurrentAddressConfirmationImageView"
                            alt="current address"
                          />
                        </div>
                      </dd>
                    </dl>
                  </dd>
                </div>
                <dt class="maxw">電話番号</dt>
                <dd>
                  <dl class="info_detail_sub">
                    <dt>携帯</dt>
                    <dd id="mobile_phone_no">{{ mobilePhoneNumberView }}</dd>
                    <dt v-show="homePhoneNumberView" id="home_phone_row">自宅</dt>
                    <dd id="home_phone_no">{{ homePhoneNumberView }}</dd>
                  </dl>
                </dd>
                <dt>メールアドレス</dt>
                <dd id="email">{{ emailView }}</dd>
                <dt class="maxw">緊急連絡先</dt>
                <dd>
                  <dl class="info_detail_sub">
                    <dt>お名前</dt>
                    <dd id="emergency_name">{{ emergencyContactNameView }}</dd>
                    <dt>電話番号</dt>
                    <dd id="emergency_phone_no">{{ emergencyContactPhoneNumberView }}</dd>
                  </dl>
                </dd>
                <dt>加入プラン</dt>
                <dd class="plan">{{ chargePlanName }}</dd>
                <dt>利用開始月</dt>
                <dd id="use_start_date">{{ useStartDateView }}</dd>
                <dt v-if="!isTransfer">パスワード</dt>
                <dd v-if="!isTransfer" id="password">{{ mask(state.password) }}</dd>
                <!-- アンケート -->
                <!-- eslint-disable-next-line -->
                <template v-for="ques in entryQuestionnaire" :key="`${ques.questionId}`">
                  <dt>
                    <span v-if="ques.required === 1" class="required"></span>
                    <template v-if="ques.linkageQuestionId"
                      >{{ getOtherName(ques.linkageQuestionId) }}(その他)</template
                    >
                    <template v-else>{{ ques.questionName }}</template>
                  </dt>
                  <dd>
                    <p
                      v-if="ques.inputType === 'text' || ques.inputType === 'textarea'"
                      :id="`${ques.inputType}-${ques.questionId}`"
                    >
                      {{ ques.answer[0] }}
                    </p>
                    <p v-if="ques.inputType === 'pulldown'">
                      <template v-for="option in ques.optionList">
                        <span
                          v-if="option.key === ques.answer[0]"
                          :id="`${ques.inputType}-${ques.questionId}`"
                          :key="option.key"
                          >{{ option.value }}</span
                        >
                      </template>
                    </p>
                    <p v-if="ques.inputType === 'radio'">
                      <template v-for="option in ques.optionList">
                        <span
                          v-if="option.key === ques.answer[0]"
                          :id="`${ques.inputType}-${ques.questionId}`"
                          :key="option.key"
                          >{{ option.value }}</span
                        >
                      </template>
                    </p>
                    <p
                      v-if="ques.inputType === 'checkbox'"
                      :id="`${ques.inputType}-${ques.questionId}`"
                      style="white-space: break-spaces"
                    >
                      <template v-for="option in ques.optionList">
                        <template v-for="ans in ques.answer">
                          <template v-if="ans === option.key">{{ `${option.value}\r\n` }}</template>
                        </template>
                      </template>
                    </p>
                  </dd>
                </template>
              </dl>
            </div>
            <div class="check_terms regist_confirm">
              <label>
                <input id="check_terms" v-model="check" type="checkbox" @change="onCheck" />
                <span>入力内容を確認し、誤りがない場合はチェックを付けてください。</span>
              </label>
            </div>
            <div class="btn_area">
              <button type="button" class="btn_l btn_neblue" @click.stop.prevent="back">
                入力内容を訂正する
              </button>
              <button
                id="submit_button"
                type="button"
                :class="buttonClass"
                :disabled="disabledFlg"
                @click.stop.prevent="regist"
              >
                申し込み完了（クレジットカード登録へ）
              </button>
            </div>
          </section>
        </div>
      </main>
    </form>
  </div>
</template>

<script>
import Common from "@/mixin/Common";
import Config from "@/conf/Config";
import { memberApi } from "@/module/Api";

/* global CARSHARE_CORP_ID */
export default {
  name: "ComfirmLayout",
  mixins: [Common],

  data() {
    return {
      isTransfer: this.state.isTransfer,
      check: false,
      disabledFlg: true,
      buttonClass: "btn_l btn_gray not-allowed",
      // for display
      corpNameView: this.state.corpName,
      belongDeptNameView: this.state.belongDeptName,
      nameView: `${this.state.lastName} ${this.state.firstName}`,
      kanaNameView: `${this.state.lastNameKana} ${this.state.firstNameKana}`,
      genderView: this.state.gender === 1 ? "男性" : "女性",
      birthDayView: `${this.state.birthYear}年${this.state.birthMonth}月${this.state.birthDate}日`,
      licenseExpirationView: `${this.state.licenseExpirationYear}年${this.state.licenseExpirationMonth}月${this.state.licenseExpirationDate}日`,
      licenceNoView: this.state.licenseNumber,
      licenseZipCodeView: this.state.licenseZipCode,
      licenseAddressView: `${this.state.licensePrefecturesName}${this.state.licenseCity}${this.state.licenseTown}`,
      licenseAddressOtherView: this.state.licenseOtherAddress ? this.state.licenseOtherAddress : "",
      uploadedFrontImageView: this.state.uploadedFrontImage,
      uploadBackImageView: this.state.uploadBackImage,
      currentZipCodeView: this.state.currentZipCode,
      currentAddressView: `${this.state.currentPrefecturesName}${this.state.currentCity}${this.state.currentTown}`,
      currentAddressOtherView: this.state.currentOtherAddress ? this.state.currentOtherAddress : "",
      uploadCurrentAddressConfirmationImageView: this.state.uploadCurrentAddressConfirmationImage,
      mobilePhoneNumberView: this.state.mobilePhoneNumber,
      homePhoneNumberView: this.state.homePhoneNumber,
      emailView: this.state.email,
      emergencyContactNameView: `${this.state.emergencyContactLastName} ${this.state.emergencyContactFirstName}`,
      emergencyContactPhoneNumberView: this.state.emergencyContactPhoneNumber,
      useStartDateView: `${this.state.useStartYear}年${this.state.useStartMonth}月`,
      entryQuestionnaire: this.state.entryQuestionnaire,
      chargePlanName: this.state.chargePlanName,
    };
  },

  computed: {
    screenId: () => "ENTR_005",
    isCorp() {
      return this.state.corpId !== 0;
    },
    isAnotherAddress() {
      return this.state.currentAddressInputed ? this.state.currentAddressInputed : false;
    },
    licenseTypeName() {
      return this.localstate.licenseTypes.find((l) => l.key === this.state.licenseType.toString())
        .value;
    },
  },

  // vue3で削除
  // filters: {
  //   mask(val) {
  //     return val.replace(/[\d\D]/g, "●");
  //   },
  // },

  methods: {
    onCheck() {
      if (this.check) {
        this.buttonClass = "btn_l btn_blue";
        this.disabledFlg = false;
      } else {
        this.buttonClass = "btn_l btn_gray not-allowed";
        this.disabledFlg = true;
      }
    },
    getOtherName(linkedId) {
      return this.entryQuestionnaire.find((e) => e.questionId === linkedId).questionName;
    },
    getRegistData() {
      let data = {};
      // 料金プラン種別
      let chargePlanType;
      if (!this.isCorp) {
        chargePlanType = 1; // 個人会員
      } else if (!this.state.cleaningOrMaintenance) {
        chargePlanType = 2; // 法人会員
      } else {
        chargePlanType = 3; // 清掃・メンテナンス会員
      }

      if (this.isTransfer) {
        // 流用登録データ
        data = {
          corpIdForCheck: this.state.corpId,
          corpId: this.state.corpId === 0 ? null : this.state.corpId,
          belongDeptName: this.state.corpId === 0 ? null : this.state.belongDeptName,
          email: this.state.email,
          subCode: this.state.subCode,
          basicChargeExistence: this.state.basicChargeExistence,
          useStartYear: this.state.useStartYear,
          useStartMonth: this.state.useStartMonth,
          enrollmentQuestionnaire: JSON.stringify(this.entryQuestionnaire),
          chargePlanType,
        };
      } else {
        // 新規登録データ
        data = {
          corpIdForCheck: this.state.corpId,
          corpId: this.state.corpId === 0 ? null : this.state.corpId,
          belongDeptName: this.state.corpId === 0 ? null : this.state.belongDeptName,
          carshareCorpId: CARSHARE_CORP_ID,
          lastName: this.state.lastName,
          firstName: this.state.firstName,
          lastNameKana: this.state.lastNameKana,
          firstNameKana: this.state.firstNameKana,
          gender: this.state.gender,
          birthYear: this.state.birthYear,
          birthMonth: this.state.birthMonth,
          birthDate: this.state.birthDate,
          licenseExpirationYear: this.state.licenseExpirationYear,
          licenseExpirationMonth: this.state.licenseExpirationMonth,
          licenseExpirationDate: this.state.licenseExpirationDate,
          licenseNumber: this.state.licenseNumber,
          licenseType: this.state.licenseType,
          licenseZipCode: this.state.licenseZipCode,
          licensePrefecturesName: this.state.licensePrefecturesName,
          licenseCity: this.state.licenseCity,
          licenseTown: this.state.licenseTown,
          licenseOtherAddress: this.state.licenseOtherAddress,
          licenseFrontImageObjectKey: this.state.licenseFrontImageObjectKey,
          licenseFrontImageVersionId: this.state.licenseFrontImageVersionId,
          licenseBackImageObjectKey: this.state.licenseBackImageObjectKey,
          licenseBackImageVersionId: this.state.licenseBackImageVersionId,
          currentAddressInputed: this.state.currentAddressInputed ? 1 : 0,
          currentZipCode: this.state.currentAddressInputed ? this.state.currentZipCode : null,
          currentPrefecturesName: this.state.currentAddressInputed
            ? this.state.currentPrefecturesName
            : null,
          currentCity: this.state.currentAddressInputed ? this.state.currentCity : null,
          currentTown: this.state.currentAddressInputed ? this.state.currentTown : null,
          currentOtherAddress: this.state.currentAddressInputed
            ? this.state.currentOtherAddress
            : null,
          currentAddressConfirmationImageObjectKey: this.state.currentAddressInputed
            ? this.state.currentAddressConfirmationImageObjectKey
            : null,
          currentAddressConfirmationImageVersionId: this.state.currentAddressInputed
            ? this.state.currentAddressConfirmationImageVersionId
            : null,
          email: this.state.email,
          mobilePhoneNumber: this.state.mobilePhoneNumber,
          homePhoneNumber: this.state.homePhoneNumber,
          emergencyContactLastName: this.state.emergencyContactLastName,
          emergencyContactFirstName: this.state.emergencyContactFirstName,
          emergencyContactPhoneNumber: this.state.emergencyContactPhoneNumber,
          password: this.state.password,
          subCode: this.state.subCode,
          basicChargeExistence: this.state.basicChargeExistence,
          useStartYear: this.state.useStartYear,
          useStartMonth: this.state.useStartMonth,
          enrollmentQuestionnaire: JSON.stringify(this.entryQuestionnaire),
          chargePlanType,
        };
      }
      return data;
    },

    back() {
      this.$emit("changeStep", "steps step_3");
      window.scroll(0, 0);
    },

    async regist() {
      // 二重submit防止 & ポインタ通信中
      this.disabledFlg = true;
      this.buttonClass = "btn_l btn_blue wait";
      const data = this.getRegistData();

      try {
        if (this.isTransfer) {
          // 会員流用登録API
          await memberApi.transferRegist(data);
        } else {
          // 会員登録API
          await memberApi.create(data);
        }

        // カード入力画面へ切り替え
        this.$emit("changeStep", "steps step_5");
        this.$emit("setComplete");
        document.cookie = `${Config.FUNCTION_ID}steps step_5; path=/`;
        window.scroll(0, 0);
      } catch (error) {
        // console.log(this.screenId, error);
        this.$logger.error(`members register API is failed. post data: ${JSON.stringify(data)}`);
        this.$emit("occurError", "API", error);
        this.buttonClass = "btn_l btn_blue";
        this.disabledFlg = false;
      }
    },
    mask(val) {
      return val.replace(/[\d\D]/g, "●");
    },
  },
};
</script>
