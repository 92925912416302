<template>
  <div>
    <div>
      <form>
        <main class="cf">
          <div class="container">
            <section class="common_section section_message">
              <div class="box cf">
                <h3>ページが表示できません。</h3>
                <p>
                  URLが正しくないか、有効期限が切れています。
                  <br />お手数ですが、下記より再度メールアドレスの登録をお願いいたします。
                </p>
              </div>
            </section>
            <div class="btn_area">
              <button type="button" class="btn_l btn_blue" @click="transitionToEntry()">
                入会申し込み画面へ
              </button>
            </div>
          </div>
        </main>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "ExpireLayout",
  data() {
    return {};
  },
  methods: {
    transitionToEntry() {
      window.location.href = "public/index.html";
    },
  },
};
</script>
