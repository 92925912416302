<template>
  <div>
    <div>
      <form>
        <main class="cf">
          <div class="container">
            <section class="common_section section_message">
              <div class="box cf">
                <h3>既に会員情報が登録されています。</h3>
                <p>
                  入力されたメールアドレスで{{
                    corpParam.serviceName
                  }}に会員情報を登録済みです。
                  <br />ご登録いただいているメールアドレスとパスワードで公式アプリにログイン可能です。
                  <br />ご不明点がございましたらカスタマーセンターまでお問い合わせください。
                  <br />
                </p>
              </div>
            </section>
          </div>
        </main>
      </form>
    </div>
  </div>
</template>

<script>
import Common from "@/mixin/Common";

export default {
  name: "RegisteredLayout",
  mixins: [Common],
};
</script>
