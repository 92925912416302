import _ from "lodash";
import { convertKeys, convertValueToString } from "@/helper/object";

export default function extractCodebyCodeKey(codeList, codeKey) {
  try {
    return _(codeList)
      .filter((code) => code.codeCategoryKey === codeKey)
      .flatMap((code) => code.codeContainer)
      .map((codeContainer) =>
        convertValueToString(
          convertKeys(
            codeContainer,
            ["codeKey", "key"],
            ["codeAlias", "value"],
            ["displayOrder", "displayOrder"]
          )
        )
      )
      .value();
  } catch (e) {
    return [];
  }
}
