<template>
  <div>
    <form>
      <main class="cf">
        <div class="container">
          <section class="common_section section_message">
            <div class="box cf">
              <h3>ご登録ありがとうございました。</h3>
              <p v-html="webContents.article.registCompInfo"></p>
            </div>
          </section>

          <section class="common_section section_apply">
            <div class="download_apps">
              <p>公式アプリダウンロード</p>
              <div class="app_bnrs">
                <a :href="corpParam.appleStoreUrl" target="_blank" rel="noopener noreferrer">
                  <img
                    src="@/assets/img/bnr_appstore.png"
                    srcset="@/assets/img/bnr_appstore.png 1x, @/assets/img/bnr_appstore@2x.png 2x"
                    alt="AppStoreからダウンロード"
                    width="159"
                    height="47"
                  />
                </a>
                <a :href="corpParam.googlePlayUrl" target="_blank" rel="noopener noreferrer">
                  <img
                    src="@/assets/img/bnr_googleplay.png"
                    srcset="
                      @/assets/img/bnr_googleplay.png    1x,
                      @/assets/img/bnr_googleplay@2x.png 2x
                    "
                    alt="GooglePlayで手に入れよう"
                    width="169"
                    height="49"
                  />
                </a>
              </div>
            </div>
          </section>
        </div>
      </main>
    </form>
  </div>
</template>
<script>
import Common from "@/mixin/Common";

export default {
  name: "CompleteLayout",
  mixins: [Common],
  computed: {
    screenId: () => "ENTR_007",
  },
};
</script>
