import * as Sentry from '@sentry/browser';

/* global CARSHARE_CORP_ID */
export default class ErrorTracking {
  static configureScope(session) {
    Sentry.configureScope((scope) => {
      scope.setTag('carshareCorpId', CARSHARE_CORP_ID);
      if (session) {
        scope.setUser({
          id: session.memberId,
          username: `${session.firstName} ${session.lastName}`,
          email: session.email,
        });
      }
    });
  }

  static captureException(err) {
    Sentry.captureException(err);
  }

  static showReportDialog() {
    Sentry.showReportDialog();
  }

  static captureMessage(message) {
    Sentry.captureMessage(message);
  }
}
