import pickupPresignParameter from '@/helper/aws';

export default {
  props: ['step', 'state', 'corpParam', 'chargePlan'],
  data() {
    const localstate = {
      ...this.state
    };
    const localCorpParam = {
      ...this.corpParam
    };
    const localChargePlan = {
      ...this.chargePlan
    }
    return {
      localstate,
      localCorpParam,
      localChargePlan
    };
  },
  methods: {
    pickupPresignParameter: (parameter) => pickupPresignParameter(parameter),
    /**
     * 会員種別判定
     * @param corpId
     * @param cleaningOrMaintenance
     */
    getCharegePlanType: (corpId, cleaningOrMaintenance) => {
      let type;
      if (corpId === 0) {
        type = 1; // 個人会員
      } else if (!cleaningOrMaintenance) {
        type = 2; // 法人会員
      } else {
        type = 3; // 清掃・メンテナンス会員
      }
      return type;
    }
  },
  computed: {
    webContents() {
      let ret = {
        article: "",
        images: ""
      };
      if (this.corpParam && this.corpParam.memberRegistrationWebContents) {
        ret = JSON.parse(this.corpParam.memberRegistrationWebContents)[
          this.screenId
        ];
      }
      return ret;
    },
  }
};
