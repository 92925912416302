<template>
  <footer>
    <p class="copyright" v-html="webContents.article.copyRight"></p>
  </footer>
</template>
<script>
import Common from "@/mixin/Common";

export default {
  name: "HeaderLayout",
  mixins: [Common],
  computed: {
    screenId: () => "FOOTER",
  },
};
</script>
