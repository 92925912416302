/* eslint-disable */
import moment from "moment";
import "moment/locale/ja";
import logger from "@/module/Logger";

const axios = require("axios");
const Swal = require("sweetalert2");

export default {
  install: (app) => {
    moment.locale("ja");
    app.config.globalProperties.$moment = moment;
    app.config.globalProperties.$logger = logger;
    app.config.globalProperties.$axios = axios;
    app.config.globalProperties.$Swal = Swal;
  },
};
