<template>
  <header>
    <div class="logo_bar">
      <div class="logo">
        <img
          :src="webContents.images.logo"
          :srcset="`${webContents.images.logo} 1x, ${webContents.images.logo2x} 2x`"
          alt="carshare corp icon"
        />
      </div>
    </div>
    <div class="page_navi">
      <div class="container">
        <h1 class="page_name">
          <span>{{ title }}</span>
        </h1>
      </div>
    </div>
  </header>
</template>

<script>
import Common from "@/mixin/Common";
import Config from "@/conf/Config";

export default {
  name: "HeaderLayout",
  mixins: [Common],
  props: ["isError"],

  computed: {
    screenId: () => "HEADER",
    title() {
      if (this.isError) {
        document.title = `${Config.ERROR_TITLE} | ${this.corpParam.serviceName}`;
        return Config.ERROR_TITLE;
      }
      if (this.step === "steps step_2") {
        document.title = `${Config.INDEX_TITLE} | ${this.corpParam.serviceName}`;
        return Config.INDEX_TITLE;
      }
      if (this.step === "steps step_3") {
        document.title = `${Config.REGISTER_TITLE} | ${this.corpParam.serviceName}`;
        return Config.REGISTER_TITLE;
      }
      if (this.step === "steps step_4") {
        document.title = `${Config.CONFIRM_TITLTE} | ${this.corpParam.serviceName}`;
        return Config.CONFIRM_TITLTE;
      }
      if (this.step === "steps step_5") {
        document.title = `${Config.CARD_TITLE} | ${this.corpParam.serviceName}`;
        return Config.CARD_TITLE;
      }
      if (this.step === "steps step_6") {
        document.title = `${Config.COMPLETE_TITLE} | ${this.corpParam.serviceName}`;
        return Config.COMPLETE_TITLE;
      }
      return "";
    },
  },
};
</script>
