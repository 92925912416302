<template>
  <div id="app">
    <HeaderLayout :step="step" :corp-param="corpParam" :is-error="isError" />
    <ErrorBoundary v-cloak>
      <NavigationLayout v-if="!isError" :step="step" />
      <IndexLayout
        v-if="step === 'steps step_2'"
        :step="step"
        :state="state"
        :corp-param="corpParam"
        :charge-plan="chargePlan"
        @occur-error="occurError"
        @change-step="changeStep"
        @view-assumed-error="viewAssumedError"
        @commit="commit"
      />
      <RegisterLayout
        v-else-if="step === 'steps step_3'"
        :step="step"
        :state="state"
        :corp-param="corpParam"
        :charge-plan="chargePlan"
        @change-step="changeStep"
        @commit="commit"
        @occur-error="occurError"
      />
      <ComfirmLayout
        v-else-if="step === 'steps step_4'"
        :step="step"
        :state="state"
        :corp-param="corpParam"
        :charge-plan="chargePlan"
        @change-step="changeStep"
        @commit="commit"
        @set-complete="setComplete"
        @occur-error="occurError"
      />
      <CardLayout
        v-else-if="step === 'steps step_5'"
        :step="step"
        :state="state"
        :corp-param="corpParam"
        @change-step="changeStep"
        @commit="commit"
        @occur-error="occurError"
      />
      <CompleteLayout v-else-if="step === 'steps step_6'" :corp-param="corpParam" />
      <ExpireLayout v-else-if="step === 'error expire'" :corp-param="corpParam" />
      <RegisteredLayout v-else-if="step === 'error registered'" :corp-param="corpParam" />
      <SorryLayout v-else :corp-param="corpParam" />
    </ErrorBoundary>
    <FooterLayout :corp-param="corpParam" />
  </div>
</template>

<script>
import queryString from "query-string";
import sleep from "@/helper/timer";
import Config from "@/conf/Config";
import { commonApi, chargePlanApi, auth } from "@/module/Api";
import ErrorTracking from "@/module/ErrorTracking";
import IndexLayout from "./components/IndexLayout.vue";
import RegisterLayout from "./components/RegisterLayout.vue";
import ComfirmLayout from "./components/ComfirmLayout.vue";
import CardLayout from "./components/CardLayout.vue";
import CompleteLayout from "./components/CompleteLayout.vue";
import ExpireLayout from "./components/ErrorExpire.vue";
import RegisteredLayout from "./components/ErrorRegistered.vue";
import SorryLayout from "./components/ErrorSorry.vue";
import HeaderLayout from "./components/HeaderLayout.vue";
import FooterLayout from "./components/FooterLayout.vue";
import NavigationLayout from "./components/NavigationLayout.vue";
import ErrorBoundary from "./components/ErrorBoundary.vue";

/* global CARSHARE_CORP_ID */
export default {
  name: "App",
  components: {
    IndexLayout,
    RegisterLayout,
    ComfirmLayout,
    CardLayout,
    CompleteLayout,
    ExpireLayout,
    RegisteredLayout,
    SorryLayout,
    HeaderLayout,
    FooterLayout,
    NavigationLayout,
    ErrorBoundary,
  },
  data() {
    return {
      state: {},
      corpParam: {},
      chargePlan: [],
      step: "steps step_2",
      apiError: false,
      isError: false,
      isCompleted: false,
    };
  },
  created() {
    const param = queryString.parse(window.location.search);
    if (param.error) {
      this.viewAssumedError(param.error);
      return;
    }

    document.cookie = `${Config.FUNCTION_ID}steps step_2; path=/`;
    this.$logger.info("front-account-registration-for-private is accessed.");
  },
  async mounted() {
    if (auth.isAuth()) {
      // 認証済みの場合はサインアウト
      this.clearSession();
    }

    // カーシェア事業者パラメータ取得
    try {
      this.corpParam = await commonApi.getCorpParam();
    } catch (err) {
      this.occurError("API", err);
    }

    localStorage.setItem(Config.SESSION_PARAM.CARSHARE_CORP_ID, CARSHARE_CORP_ID);
    ErrorTracking.configureScope();
    // 料金プラン取得
    this.chargePlan = await chargePlanApi.findById(CARSHARE_CORP_ID);

    // 離脱時アラート
    const self = this;
    // eslint-disable-next-line
    window.onbeforeunload = function () {
      if (!self.isCompleted) {
        self.$logger.info(
          `cognito@${self.state.subCode} email@${self.state.email} user is trying to leave page from ${self.step}`
        );
        return "編集内容が破棄されてしまいます。よろしいですか？";
      }
      // 会員登録以降のページでは出さない
      return null;
    };
  },
  methods: {
    async occurError(type, err) {
      // メンテナンスモード
      if (type === "API" && err.response.data.code === "maintenance") {
        this.setComplete(); // 離脱確認解除
        window.location.href = "/public/maintenance.html";
      }
      // API通信中のエラーは一度目はモーダル表示でリトライを促す
      else if (type === "API" && !this.apiError) {
        this.$Swal.fire({
          icon: "warning",
          title: "通信エラーが発生しました",
          text: "お手数ですが、再度お試しください。",
          customClass: "medium_text",
          grow: "row",
        });
        this.apiError = true;
        await sleep(5000);
      } else {
        // 二回目のAPIエラー or それ以外のエラー時
        this.isError = true;
        throw err;
      }
    },
    changeStep(step) {
      const preview = this.step;
      this.step = step;
      this.$logger.info(
        `cognito@${this.state.subCode} email@${this.state.email} user is changing step to ${this.step} from ${preview}`
      );
    },
    viewAssumedError(error) {
      this.step = `error ${error}`;
      this.isError = true;
    },
    commit(state) {
      this.state = state;
    },
    setComplete() {
      this.isCompleted = true;
    },
    clearSession() {
      auth.getCurrentUser().signOut();
      localStorage.removeItem(Config.SESSION_PARAM.CARSHARE_CORP_ID);
      localStorage.removeItem(Config.SESSION_PARAM.COGNITO_ID);
      localStorage.removeItem(Config.SESSION_PARAM.MEMBER_ID);
      localStorage.removeItem(Config.SESSION_PARAM.REFRESH_TOKEN);
    },
  },
};
</script>
