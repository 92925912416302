<template>
  <div>
    <div v-if="error">
      <form>
        <main class="cf">
          <div class="container">
            <section class="common_section section_message">
              <div class="box cf">
                <h3>エラーが発生しました。</h3>
                <p>
                  システムエラーが発生しました。
                  <br />ご不便とご面倒をおかけしますが、しばらく時間をおいてから再度ご利用ください。
                </p>
              </div>
            </section>
          </div>
        </main>
      </form>
    </div>
    <div v-else>
      <slot />
    </div>
  </div>
</template>

<script>
import Config from "@/conf/Config";
import ErrorTracking from "@/module/ErrorTracking";
import sleep from "@/helper/timer";

export default {
  data() {
    return {
      error: null,
      info: null,
    };
  },
  async errorCaptured(err, vm, info) {
    this.error = err;
    this.info = info;
    this.$logger.error(err);
    if (window.location.host !== Config.LOCAL_HOST) {
      ErrorTracking.captureException({
        error: err,
        info,
      });
      await sleep(2000);
      ErrorTracking.showReportDialog();
    }
  },
};
</script>
