<template>
  <div>
    <div>
      <form>
        <main class="cf">
          <div class="container">
            <section class="common_section section_message">
              <div class="box cf">
                <h3>エラーが発生しました。</h3>
                <p>
                  システムエラーが発生しました。
                  <br />ご不便とご面倒をおかけしますが、しばらく時間をおいてから再度ご利用ください。
                </p>
              </div>
            </section>
          </div>
        </main>
      </form>
    </div>
  </div>
</template>

<script>
import Common from "@/mixin/Common";

export default {
  name: "SorryLayout",
  mixins: [Common],
};
</script>
