import Config from "@/conf/Config";

const axios = require('axios');
const AmazonCognitoIdentity = require('amazon-cognito-identity-js');

const poolData = POOL_DATA || {};
const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

class Api {

  static getHeader = (param) => ({
    params: param,
    headers: {
      "X-App-Type": "front",
      "X-Carshare_Corp-Id": localStorage.getItem(Config.SESSION_PARAM.CARSHARE_CORP_ID),
      "Content-Type": "application/json",
      'cache-control': 'no-cache',
      "X-Cognito-Id": localStorage.getItem(Config.SESSION_PARAM.COGNITO_ID),
      "X-User-Id": localStorage.getItem(Config.SESSION_PARAM.MEMBER_ID),
      "X-Refresh-Token": localStorage.getItem(Config.SESSION_PARAM.REFRESH_TOKEN)
    }
  });

  static checkStatus = response => {
    if (response.status >= 200 && response.status < 300) {
      return response;
    }
    const r = response.response ? response.response : response;
    const error = new Error(r.statusText);
    error.response = r;
    error.code = r.data.code === "maintenance" ? r.data.code : r.data.errorCode;
    throw error;
  };

  static toDist = response => response.data.data[0];

  static toList = response => response.data.data;

  static axiosGet = (base, param) =>
    axios.get(
      base, this.getHeader(param)
    )
    .then(this.checkStatus).catch(this.checkStatus)

  static axiosPost = (url, data) =>
    axios.post(url, JSON.stringify(data), this.getHeader())
    .then(this.checkStatus).catch(this.checkStatus);

  static axiosPostFile = (url, data) =>
    axios.post(url, data, {
      headers: {
        "content-type": "multipart/form-data"
      }
    })
    .then(this.checkStatus).catch(this.checkStatus);
}

const API_CONFIG = {};
API_CONFIG.HOST = window.location.origin;
API_CONFIG.BASE_URL = `${API_CONFIG.HOST}/admin`;
API_CONFIG.BASE_URL_COMMON = `${API_CONFIG.BASE_URL}/api/common`;
API_CONFIG.ENDPOINT_CORPORATION = `${API_CONFIG.BASE_URL}/api/corporation`;
API_CONFIG.ENDPOINT_SESSION = `${API_CONFIG.BASE_URL}/api/session/front_web`;
API_CONFIG.ENDPOINT_MEMBER = `${API_CONFIG.BASE_URL}/api/members`;
API_CONFIG.ENDPOINT_CHARGE_PLAN = `${API_CONFIG.BASE_URL}/api/charge_plan_web`;
API_CONFIG.ENDPOINT_CODE = `${API_CONFIG.BASE_URL}/api/code`;
API_CONFIG.ENDPOINT_QUESTION = `${API_CONFIG.BASE_URL}/api/questionnaire`;
API_CONFIG.ENDPOINT_IMAGE = `${API_CONFIG.BASE_URL}/api`;
API_CONFIG.ENDPOINT_CARD = `${API_CONFIG.BASE_URL}/api/credit_card/entry`;
API_CONFIG.ENDPOINT_ZIPCODE = `${API_CONFIG.BASE_URL}/api/zip_code`;

export const auth = {
  isAuth: () => userPool.getCurrentUser() != null,

  getCurrentUser: () => userPool.getCurrentUser()
}

/**
 * Cognito login
 */
export const loginApi = {
  login: (userName, password) => {
    const userData = {
      Username: userName,
      Pool: userPool,
    };

    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
    const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails({
      Username: userName,
      Password: password,
    }, );
    return new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (result) => {
          resolve(result);
        },
        onFailure: (err) => {
          reject(err);
        },
      });
    });
  }
};

export const masterApi = {
  getCodeCategory: () => Api.axiosGet(`${API_CONFIG.BASE_URL}/api/codeCategory`).then(Api.toDist)
};

export const corporationApi = {
  getCorpEntryInfo: id =>
    Api.axiosGet(`${API_CONFIG.ENDPOINT_CORPORATION}/corp_entry_info/${id}`).then(Api.toDist)
};

export const sessionApi = {
  getSession: sub => Api.axiosGet(`${API_CONFIG.ENDPOINT_SESSION}/${sub}`).then(Api.toDist)
};

export const memberApi = {
  findById: (id, carshareCorpId) =>
    Api.axiosGet(`${API_CONFIG.ENDPOINT_MEMBER}/${id}`, {
      "carshareCorpId": carshareCorpId
    }).then(Api
      .toDist),
  create: data => Api.axiosPost(API_CONFIG.ENDPOINT_MEMBER, data).then(Api.toList),
  transferRegist: data => Api.axiosPost(`${API_CONFIG.ENDPOINT_MEMBER}/transfer_regist`, data)
    .then(Api.toList)
};

export const chargePlanApi = {
  findById: id => Api.axiosGet(`${API_CONFIG.ENDPOINT_CHARGE_PLAN}`, {
    "carshareCorpId": id
  }).then(Api
    .toDist)
};

export const commonApi = {
  getCorpParam: () =>
    Api.axiosGet(
      `${API_CONFIG.BASE_URL_COMMON}/carshare_corp_param`, {
        "domain": API_CONFIG.HOST
      }
    )
    .then(Api.toDist),
  getServiceList: (email) =>
    Api.axiosGet(`${API_CONFIG.BASE_URL_COMMON}/service_list_web`, {
      "exclusionFlg": 1,
      "email": email
    }).then(Api
      .toList),
  getBelongDeptName: id =>
    Api.axiosGet(`${API_CONFIG.BASE_URL_COMMON}/department_list`, {
      corpId: id,
    }).then(Api.toDist)

};
export const codeApi = {
  getGeneralCodes: (codeKeys) =>
    Api.axiosGet(`${API_CONFIG.ENDPOINT_CODE}`, {
      "codeKeys": codeKeys
    }).then(Api.toList),
};

export const questionApi = {
  getQuestion: () =>
    Api.axiosGet(`${API_CONFIG.ENDPOINT_QUESTION}/entryQuestionnaire`).then(Api.toList),
};

export const imageApi = {
  getImage: (objKey, versionId, corpId, email) =>
    Api.axiosGet(
      `${API_CONFIG.ENDPOINT_IMAGE}/private_download_image_web`, {
        "objectKey": objKey,
        "versionId": versionId,
        "corpIdForCheck": corpId,
        "email": email
      }
    ).then(Api.toDist),
  uploadImage: (params) =>
    Api.axiosPostFile(`${API_CONFIG.ENDPOINT_IMAGE}/private_upload_image`, params).then(Api
      .toDist),
};

export const cardApi = {
  entry: (data) =>
    Api.axiosPost(`${API_CONFIG.ENDPOINT_CARD}`, data).then(Api.toList),
};

export const zipcodeApi = {
  getZipcode: (zipcode) =>
    Api.axiosGet(`${API_CONFIG.ENDPOINT_ZIPCODE}`, {
      "zipCode": zipcode
    }).then(Api.toDist),
};
