<template>
  <div class="step_flow">
    <div class="container">
      <ul :class="step">
        <li>
          <span>STEP<strong>1</strong></span
          >入会手続きの確認
        </li>
        <li>
          <span>STEP<strong>2</strong></span
          >規約に同意
        </li>
        <li>
          <span>STEP<strong>3</strong></span
          >会員情報入力
        </li>
        <li>
          <span>STEP<strong>4</strong></span
          >会員情報確認
        </li>
        <li>
          <span>STEP<strong>5</strong></span
          >入会受付完了<br />クレジットカード情報入力
        </li>
        <li>
          <span>STEP<strong>6</strong></span
          >会員情報登録完了
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Common from "@/mixin/Common";

export default {
  name: "NavigationLayout",
  mixins: [Common],

  data() {
    return {};
  },
};
</script>
