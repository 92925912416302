<template>
  <div>
    <Form v-slot="{ meta }" ref="observer" onsubmit="return false;">
      <main class="cf">
        <div class="container">
          <section class="common_section basic_info">
            <div class="box">
              <dl class="info_detail">
                <dt v-if="isCorp" id="corp_name_row">法人名</dt>
                <dd v-if="isCorp" id="corp_name">{{ corpName }}</dd>
                <dt v-if="isCorp" id="belong_dept_name_row">部署名</dt>
                <dd v-if="isCorp" id="belong_dept_name">
                  <vue3-simple-typeahead
                    :id="'input-belong_dept_name'"
                    v-model="formData.belongDeptName"
                    placeholder="例：第一営業部"
                    :items="belongDeptNameList"
                    :min-input-length="0"
                    maxlength="100"
                  >
                  </vue3-simple-typeahead>
                </dd>
                <dt><span class="required"></span>お名前</dt>
                <dd>
                  <div class="parallel">
                    <span class="name_adj">姓</span>
                    <Field
                      id="input_lastName"
                      v-model="formData.localLastName"
                      rules="required|max:50"
                      name="姓"
                      type="text"
                      size="8"
                      placeholder="例：鈴木"
                      :disabled="isTransfer"
                    />
                    <br />
                    <ErrorMessage name="姓" />
                  </div>
                  <div class="parallel">
                    <span class="name_adj">名</span>
                    <Field
                      id="input_firstName"
                      v-model="formData.localFirstName"
                      rules="required|max:50"
                      name="名"
                      type="text"
                      size="7"
                      placeholder="例：太郎"
                      :disabled="isTransfer"
                    />
                    <br />
                    <ErrorMessage name="名" />
                  </div>
                </dd>
                <dt><span class="required"></span>お名前（フリガナ）</dt>
                <dd>
                  <div class="parallel">
                    <span class="name_adj">セイ</span>
                    <Field
                      id="input_kana_lastName"
                      v-model="formData.locallastNameKana"
                      rules="required|katakana|max:50"
                      name="セイ"
                      type="text"
                      size="8"
                      placeholder="例：スズキ"
                      :disabled="isTransfer"
                    />
                    <br />
                    <ErrorMessage name="セイ" />
                  </div>
                  <div class="parallel">
                    <span class="name_adj">メイ</span>
                    <Field
                      id="input_kana_firstName"
                      v-model="formData.localfirstNameKana"
                      name="メイ"
                      rules="required|katakana|max:50"
                      type="text"
                      size="7"
                      placeholder="例：タロウ"
                      :disabled="isTransfer"
                    />
                    <br />
                    <ErrorMessage name="メイ" />
                  </div>
                </dd>
                <dt><span class="required"></span>性別</dt>
                <dd>
                  <!-- eslint-disable-next-line -->
                  <label>
                    <Field
                      v-model="formData.localGender"
                      rules="required"
                      type="radio"
                      name="性別"
                      class="input_gender_men"
                      :value="1"
                      :disabled="isTransfer"
                    /><span>男性</span></label
                  >
                  <!-- eslint-disable-next-line -->
                  <label>
                    <Field
                      v-model="formData.localGender"
                      rules="required"
                      type="radio"
                      name="性別"
                      class="input_gender_woman"
                      :value="2"
                      :disabled="isTransfer"
                    /><span>女性</span></label
                  >
                  <br />
                  <ErrorMessage name="性別" />
                </dd>
                <dt><span class="required"></span>生年月日</dt>
                <dd>
                  <span class="name_adj">西暦</span>
                  <Field
                    id="birthYear"
                    v-model="formData.localBirthYear"
                    rules="required"
                    as="select"
                    name="生年"
                    class="xsw"
                    :disabled="isTransfer"
                    @change="getMaxDays"
                  >
                    <option value>選択</option>
                    <option
                      v-for="n in maxBirthYear - 1900"
                      :key="maxBirthYear - n"
                      :value="maxBirthYear - n"
                    >
                      {{ maxBirthYear - n }}
                    </option>
                  </Field>

                  <span class="name_adj">年</span>
                  <Field
                    id="birthMonth"
                    v-model="formData.localBirthMonth"
                    rules="required"
                    as="select"
                    name="生月"
                    class="xsw"
                    :disabled="isTransfer"
                    @change="getMaxDays"
                  >
                    <option value>選択</option>
                    <option v-for="n in 12" :key="n" :value="n">{{ n }}</option>
                  </Field>

                  <span class="name_adj">月</span>
                  <Field
                    id="birthDate"
                    v-model="formData.localBirhDate"
                    rules="required"
                    name="生日"
                    as="select"
                    class="xsw"
                    :disabled="isTransfer"
                  >
                    <option value>選択</option>
                    <option v-for="n in maxDays" :key="n" :value="n">{{ n }}</option>
                  </Field>

                  <span class="name_adj">日</span>

                  <p><ErrorMessage name="生年" /></p>
                  <p><ErrorMessage name="生月" /></p>
                  <p><ErrorMessage name="生日" /></p>
                </dd>
                <dt class="maxw">運転免許証情報</dt>
                <dd>
                  <dl class="info_detail_sub">
                    <div class="licence_info cf">
                      <div class="license_info_mid">運転免許の入力部分</div>
                      <img
                        src="@/assets/img/fig_license_info.png"
                        srcset="
                          @/assets/img/fig_license_info.png    1x,
                          @/assets/img/fig_license_info@2x.png 2x
                        "
                        alt="license_info"
                        width="361"
                        height="178"
                      />
                      <div class="year_table">
                        <div class="table_header">平成、令和の早見表</div>
                        <div class="table_disp">
                          <table class="table_s">
                            <tr>
                              <th>西暦</th>
                              <th>平成</th>
                              <th>令和</th>
                            </tr>
                            <tr>
                              <td>2020年</td>
                              <td>32年</td>
                              <td>2年</td>
                            </tr>
                            <tr>
                              <td>2021年</td>
                              <td>33年</td>
                              <td>3年</td>
                            </tr>
                            <tr>
                              <td>2022年</td>
                              <td>34年</td>
                              <td>4年</td>
                            </tr>
                            <tr>
                              <td>2023年</td>
                              <td>35年</td>
                              <td>5年</td>
                            </tr>
                            <tr>
                              <td>2024年</td>
                              <td>36年</td>
                              <td>6年</td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                    <dt><span class="required"></span>有効期限</dt>
                    <dd>
                      <span class="name_adj">西暦</span>
                      <Field
                        id="expirationYear"
                        v-model="formData.localLicenseExpirationYear"
                        rules="required"
                        as="select"
                        name="有効期限(年)"
                        class="xsw"
                        :disabled="isTransfer"
                        @change="getLicenceMaxDays"
                      >
                        <option value>選択</option>
                        <option v-for="n in 7" :key="lastYear + n" :value="lastYear + n">
                          {{ lastYear + n }}
                        </option>
                      </Field>

                      <span class="name_adj">年</span>
                      <Field
                        id="expirationMonth"
                        v-model="formData.localLicenseExpirationMonth"
                        rules="required"
                        as="select"
                        name="有効期限(月)"
                        class="xsw"
                        :disabled="isTransfer"
                        @change="getLicenceMaxDays"
                      >
                        <option value>選択</option>
                        <option v-for="n in 12" :key="n" :value="n">{{ n }}</option>
                      </Field>

                      <span class="name_adj">月</span>
                      <Field
                        id="expirationDate"
                        v-model="formData.localLicenseExpirationDate"
                        rules="required"
                        as="select"
                        name="有効期限(日)"
                        class="xsw"
                        :disabled="isTransfer"
                      >
                        <option value>選択</option>
                        <option v-for="n in licenceMaxDays" :key="n" :value="n">{{ n }}</option>
                      </Field>

                      <span class="name_adj">日</span>
                      <p><ErrorMessage name="有効期限(年)" /></p>
                      <p><ErrorMessage name="有効期限(月)" /></p>
                      <p><ErrorMessage name="有効期限(日)" /></p>
                    </dd>
                    <dt><span class="required"></span>免許証番号</dt>
                    <dd>
                      <span class="name_adj">第</span>
                      <Field
                        id="input_licenceNo"
                        v-model="formData.localLicenceNo"
                        rules="required|digits:12"
                        name="免許証番号"
                        type="text"
                        placeholder="例：123456789012"
                        class="hw"
                        :disabled="isTransfer"
                      />
                      <span class="name_adj">号</span>
                      <br />
                      <ErrorMessage name="免許証番号" />
                    </dd>
                    <dt><span class="required"></span>免許証種別</dt>
                    <dd>
                      <Field
                        id="select_licenseType"
                        v-model="formData.localLicenseType"
                        rules="required"
                        as="select"
                        name="免許証種別"
                        class="select_licence_type"
                        :disabled="isTransfer"
                      >
                        <option value>選択してください</option>
                        <option v-for="n in licenseTypes" :key="n.key" :value="n.key">
                          {{ n.value }}
                        </option>
                      </Field>
                      <br />
                      <ErrorMessage name="免許証種別" />
                    </dd>
                    <div class="license_upload">
                      <h4><span class="required"></span>免許証写真ファイルのアップロード</h4>
                      <p>
                        <span class="memo"
                          >※裏面に何も記載がない場合も両面の画像をご登録ください。</span
                        >
                        <br />
                        <a
                          :href="corpParam.licenseNotesUrl"
                          target="_blank"
                          rel="noopener noreferrer"
                          class="info_link"
                          >運転免許証に関する注意事項</a
                        >
                      </p>
                      <div class="upload_files">
                        <div class="upload_file_name">免許証 表</div>
                        <div class="upload_file_box">
                          <input
                            id="front_file"
                            type="file"
                            style="display: none"
                            :accept="acceptImageFormat"
                            :disabled="disabledFlg || isTransfer"
                            @change="onFrontFileChange"
                          />
                          <label for="front_file">
                            <span
                              class="upload_btn"
                              :style="
                                disabledFlg || isTransfer
                                  ? 'background-color: #d3d3d3'
                                  : 'background-color: #007CCE'
                              "
                              >ファイルを選択</span
                            >
                          </label>
                          <p v-show="frontImgErros" style="color: red">{{ frontImgErros[0] }}</p>
                          <Field
                            id="hidden_front_img"
                            v-model="formData.localUploadedFrontImage"
                            rules="required"
                            name="免許証画像表"
                            type="hidden"
                          />

                          <br />
                          <ErrorMessage name="免許証画像表" />
                          <div class="file_disp">
                            <div v-show="!formData.localUploadedFrontImage" class="file_preview">
                              {{ frontImgLoading ? "読み込み中…" : "プレビュー" }}
                            </div>
                            <img
                              v-show="formData.localUploadedFrontImage"
                              class="file_preview"
                              alt="licence surface"
                              :src="formData.localUploadedFrontImage"
                            />
                          </div>
                        </div>
                        <div class="upload_file_name">免許証 裏</div>
                        <div class="upload_file_box">
                          <input
                            id="back_file"
                            type="file"
                            style="display: none"
                            :accept="acceptImageFormat"
                            :disabled="disabledFlg || isTransfer"
                            @change="onBackFileChange"
                          />
                          <label for="back_file">
                            <span
                              class="upload_btn"
                              :style="
                                disabledFlg || isTransfer
                                  ? 'background-color: #d3d3d3'
                                  : 'background-color: #007CCE'
                              "
                              >ファイルを選択</span
                            >
                          </label>
                          <p v-show="backImgErrors" style="color: red">{{ backImgErrors[0] }}</p>
                          <Field
                            id="hidden_back_img"
                            v-model="formData.localUploadBackImage"
                            rules="required"
                            name="免許証画像裏"
                            type="hidden"
                          />
                          <br />
                          <ErrorMessage name="免許証画像裏" />

                          <div class="file_disp">
                            <div v-show="!formData.localUploadBackImage" class="file_preview">
                              {{ backImgLoading ? "読み込み中…" : "プレビュー" }}
                            </div>
                            <img
                              v-show="formData.localUploadBackImage"
                              class="file_preview"
                              alt="licence back"
                              :src="formData.localUploadBackImage"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </dl>
                </dd>
                <dt class="maxw">
                  運転免許証記載の住所
                  <br />
                  <p class="memo">
                    ※免許証記載住所の変更手続きを行っている場合は、変更後の住所を入力してください。
                  </p>
                </dt>
                <dd>
                  <dl class="info_detail_sub">
                    <dt><span class="required"></span>郵便番号</dt>
                    <dd>
                      <Field
                        id="input_zipcode"
                        v-model="formData.localLicenseZipCode"
                        rules="required|digits:7"
                        name="郵便番号"
                        type="text"
                        maxlength="7"
                        size="10"
                        placeholder="例：1710022"
                        :disabled="isTransfer"
                      />
                      <button
                        type="button"
                        class="btn_s btn_neblue"
                        :disabled="isTransfer"
                        @click="getAddressFromZipCode(formData.localLicenseZipCode, 'license')"
                      >
                        住所検索
                      </button>
                      <br />
                      <ErrorMessage name="郵便番号" />

                      <p v-show="!licenseZipcodeExistence" style="color: red">
                        該当する郵便番号が見つかりません
                      </p>
                    </dd>
                    <dt><span class="required"></span>都道府県</dt>
                    <dd>
                      <Field
                        id="select_prefecture"
                        v-model="formData.localLicensePrefecturesName"
                        rules="required"
                        name="都道府県"
                        as="select"
                        class="sw"
                        :disabled="isTransfer"
                      >
                        <option value>選択してください</option>
                        <option v-for="n in prefecturesList" :key="n.key" :value="n.value">
                          {{ n.value }}
                        </option>
                      </Field>
                    </dd>
                    <dt><span class="required"></span>市区町村</dt>
                    <dd>
                      <Field
                        id="input_city"
                        v-model="formData.localLicenseCity"
                        rules="required|max:50"
                        name="市区町村"
                        type="text"
                        placeholder="例：豊島区"
                        class="mw"
                        :disabled="isTransfer"
                      />
                      <br />
                      <ErrorMessage name="市区町村" />
                    </dd>
                    <dt><span class="required"></span>町名・番地</dt>
                    <dd>
                      <Field
                        id="input_town"
                        v-model="formData.localLicenseTown"
                        rules="required|max:50"
                        name="町名・番地"
                        type="text"
                        placeholder="例：南池袋一丁目16番15号"
                        class="mw"
                        :disabled="isTransfer"
                      />
                      <br />
                      <ErrorMessage name="町名・番地" />
                    </dd>
                    <dt>
                      以降の住所
                      <p class="memo">（マンション名・ビル名・部屋番号等）</p>
                    </dt>
                    <dd>
                      <Field
                        id="input_other"
                        v-model="formData.localLicenseOtherAddress"
                        rules="max:50"
                        name="以降の住所"
                        type="text"
                        placeholder="例：ダイヤゲート池袋5階"
                        class="maxw"
                        :disabled="isTransfer"
                      /><br />
                      <ErrorMessage name="" />
                    </dd>
                  </dl>
                </dd>
              </dl>
            </div>

            <div class="box another_address">
              <div class="another_address_check">
                <!-- eslint-disable-next-line -->
                <label>
                  <!-- TODO: ここでの設定値を必須の条件にして以下の項目のルールを設定する -->
                  <Field
                    id="another_address_check"
                    v-model="formData.localAnotherAddress"
                    type="checkbox"
                    name="currentAddressInputed"
                    :disabled="isTransfer"
                    value="true"
                  />
                  <span>運転免許証記載の住所と現住所が異なる場合</span>
                </label>
              </div>
              <transition name="trans_slide">
                <dl v-if="formData.localAnotherAddress" class="info_detail menu_slide_accordion">
                  <dd class="nohead">
                    <dl class="info_detail_sub">
                      <dt><span class="required"></span>郵便番号</dt>
                      <dd>
                        <Field
                          id="current_zipcode"
                          v-model="formData.localCurrentZipCode"
                          rules="required_if:currentAddressInputed,true|digits:7"
                          name="郵便番号(現住所)"
                          type="text"
                          maxlength="7"
                          size="10"
                          placeholder="例：1710022"
                          class="sw"
                          :disabled="isTransfer"
                        />
                        <button
                          type="button"
                          class="btn_s btn_neblue"
                          :disabled="isTransfer"
                          @click="getAddressFromZipCode(formData.localCurrentZipCode, 'current')"
                        >
                          住所検索
                        </button>

                        <br /><ErrorMessage name="郵便番号(現住所)" />
                        <p v-show="!currentZipcodeExistence" style="color: red">
                          該当する郵便番号が見つかりません
                        </p>
                      </dd>
                      <dt><span class="required"></span>都道府県</dt>
                      <dd>
                        <Field
                          id="select_current_prefecture"
                          v-model="formData.localCurrentPrefecturesName"
                          rules="required_if:currentAddressInputed,true"
                          name="都道府県(現住所)"
                          as="select"
                          class="mw"
                          :disabled="isTransfer"
                        >
                          <option value>選択してください</option>
                          <option v-for="n in prefecturesList" :key="n.key" :value="n.value">
                            {{ n.value }}
                          </option>
                        </Field>
                      </dd>
                      <dt><span class="required"></span>市区町村</dt>
                      <dd>
                        <Field
                          id="current_city"
                          v-model="formData.localCurrentCity"
                          rules="required_if:currentAddressInputed,true|max:50"
                          name="市区町村(現住所)"
                          type="text"
                          placeholder="例：豊島区"
                          class="mw"
                          :disabled="isTransfer"
                        />
                        <br /><ErrorMessage name="市区町村(現住所)" />
                      </dd>
                      <dt><span class="required"></span>町名・番地</dt>
                      <dd>
                        <Field
                          id="current_town"
                          v-model="formData.localCurrentTown"
                          rules="required_if:currentAddressInputed,true|max:50"
                          name="町名・番地(現住所)"
                          type="text"
                          placeholder="例：南池袋一丁目16番15号"
                          class="mw"
                          :disabled="isTransfer"
                        /><br /><ErrorMessage name="町名・番地(現住所)" />
                      </dd>
                      <dt>
                        以降の住所
                        <p class="memo">（マンション名・ビル名・部屋番号等）</p>
                      </dt>
                      <dd>
                        <Field
                          v-model="formData.localCurrentOtherAddress"
                          rules="max:50"
                          name="以降の住所(現住所)"
                          type="text"
                          placeholder="例：ダイヤゲート池袋5階"
                          class="maxw"
                          :disabled="isTransfer"
                        />
                        <br /><ErrorMessage name="以降の住所(現住所)" />
                      </dd>
                      <dt><span class="required"></span>現住所が確認できる書類</dt>
                      <dd>
                        <input
                          id="confirmation_file"
                          type="file"
                          style="display: none"
                          :accept="acceptImageFormat"
                          :disabled="disabledFlg || isTransfer"
                          @change="onCurrentAddressConfirmationFileChange"
                        />
                        <label for="confirmation_file">
                          <span
                            class="upload_btn"
                            :style="
                              disabledFlg || isTransfer
                                ? 'background-color: #d3d3d3'
                                : 'background-color: #007CCE'
                            "
                            >ファイルを選択</span
                          >
                        </label>
                        <p v-show="confirmationImgErrors" style="color: red">
                          {{ confirmationImgErrors[0] }}
                        </p>
                        <Field
                          id="hidden_current_img"
                          v-model="formData.localUploadCurrentAddressConfirmationImage"
                          rules="required_if:currentAddressInputed,true"
                          name="現住所確認書類"
                          type="hidden"
                        />
                        <br /><ErrorMessage name="現住所確認書類" />

                        <div class="file_disp">
                          <div
                            v-show="!formData.localUploadCurrentAddressConfirmationImage"
                            class="file_preview"
                            style="margin-top: 12px"
                          >
                            {{ currentAddressImgLoading ? "読み込み中…" : "プレビュー" }}
                          </div>
                          <img
                            v-show="formData.localUploadCurrentAddressConfirmationImage"
                            class="file_preview"
                            style="margin-top: 12px"
                            alt="current address"
                            :src="formData.localUploadCurrentAddressConfirmationImage"
                          />
                        </div>
                        <div
                          class="license_ness"
                          v-html="webContents.article.addressConfInfo"
                        ></div>
                      </dd>
                    </dl>
                  </dd>
                </dl>
              </transition>
            </div>

            <div class="box">
              <dl class="info_detail">
                <dt class="maxw">
                  電話番号
                  <p class="memo">※ハイフンなし</p>
                </dt>
                <dd>
                  <dl class="info_detail_sub">
                    <dt><span class="required"></span>携帯</dt>
                    <dd>
                      <Field
                        id="input_mobilePhone"
                        v-model="formData.localMobilePhoneNumber"
                        rules="required|digits:11"
                        name="携帯電話番号"
                        type="text"
                        placeholder="例：09012345678"
                        class="hw"
                        :disabled="isTransfer"
                      />
                      <br /><ErrorMessage name="携帯電話番号" />
                    </dd>
                    <dt>自宅</dt>
                    <dd>
                      <Field
                        v-model="formData.localHomePhoneNumber"
                        rules="numeric|phone_number_digits"
                        name="自宅電話番号"
                        type="text"
                        placeholder="例：0312345678"
                        class="hw"
                        :disabled="isTransfer"
                      /><br /><ErrorMessage name="自宅電話番号" />
                    </dd>
                  </dl>
                </dd>
                <dt>メールアドレス</dt>
                <dd id="email">{{ email }}</dd>
                <dt class="maxw">緊急連絡先</dt>
                <dd>
                  <dl class="info_detail_sub">
                    <dt><span class="required"></span>お名前</dt>
                    <dd>
                      <div class="parallel">
                        <span class="name_adj">姓</span>

                        <Field
                          id="emergency_lastName"
                          v-model="formData.localEmergencyContactLastName"
                          rules="required|max:50"
                          name="緊急連絡先(姓)"
                          type="text"
                          size="8"
                          placeholder="例：鈴木"
                          :disabled="isTransfer"
                        />
                        <br /><ErrorMessage name="緊急連絡先(姓)" />
                      </div>
                      <div class="parallel">
                        <span class="name_adj">名</span>
                        <Field
                          id="emergency_firstName"
                          v-model="formData.localEmergencyContactFirstName"
                          rules="required|max:50|differentFullName:@姓,@名,@緊急連絡先(姓)"
                          name="緊急連絡先(名)"
                          type="text"
                          size="7"
                          placeholder="例：太郎"
                          :disabled="isTransfer"
                        />
                        <br /><ErrorMessage name="緊急連絡先(名)" />
                      </div>
                      <!-- <p
                        v-show="!nameValidateFlg"
                        id="name_disagreement_error"
                        class="parallel_message"
                      >
                        ご自身の連絡先とは別の連絡先を入力してください
                      </p> -->
                    </dd>
                    <dt>
                      <span class="required"></span>電話番号
                      <p class="memo">※ハイフンなしで入力してください</p>
                    </dt>
                    <dd>
                      <Field
                        id="emergency_phoneNumber"
                        v-model="formData.localEmergencyContactPhoneNumber"
                        rules="required|numeric|phone_number_digits|confirm_phone_number:@携帯電話番号"
                        name="電話番号"
                        type="text"
                        placeholder="例：0312345678"
                        class="hw"
                        :disabled="isTransfer"
                      />
                      <br /><ErrorMessage name="電話番号" />
                    </dd>
                  </dl>
                </dd>
                <dt><span class="required"></span>プラン選択</dt>
                <dd id="plan">
                  <span name="individual">
                    <label v-for="p in targetChargePlan" :key="p.chargePlanId">
                      <Field
                        v-if="targetChargePlan.length > 1"
                        v-model="formData.localBasicChargeExistence"
                        rules="required"
                        name="プランの選択"
                        type="radio"
                        :value="p.basicChargeExistence"
                      />
                      <input v-else v-model="formData.localBasicChargeExistence" type="hidden" />
                      <span>{{ p.chargePlanName }}</span>
                    </label>
                  </span>
                  <a
                    :href="corpParam.fareUrl"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="info_link"
                    >料金プラン詳細</a
                  >
                </dd>
                <dt><span class="required"></span>利用開始月</dt>
                <dd id="use_start">
                  <span v-if="formData.localBasicChargeExistence === 1" name="canSelect">
                    <Field
                      id="select_useStartDate"
                      v-model="formData.localUseStartDate"
                      rules="required"
                      name="利用開始月の選択"
                      as="select"
                      class="hw"
                      @change="onUseStartDateChange"
                    >
                      <option value>選択してください</option>
                      <option :value="nowAndNextMonth[0].value">
                        {{ nowAndNextMonth[0].text }}
                      </option>
                      <option :value="nowAndNextMonth[1].value">
                        {{ nowAndNextMonth[1].text }}
                      </option>
                    </Field>
                    <p class="memo">※利用開始月より月額基本料金の支払いが発生します。</p>
                  </span>
                  <span v-else name="nowFixed">{{ nowAndNextMonth[0].text }}</span>
                </dd>
                <dt v-if="!isTransfer">
                  <span class="required"></span>パスワード
                  <p class="memo">（大文字小文字含む英数字8~16文字）</p>
                </dt>
                <dd v-if="!isTransfer">
                  <Field
                    id="input_password"
                    v-model="formData.localPassword"
                    rules="required|password|min:8|max:16"
                    vid="originPassword"
                    name="パスワード"
                    :type="passwordShow ? 'text' : 'password'"
                    class="sw"
                  />
                  <span class="field-icon">
                    <i
                      :class="passwordShow ? 'material-icons-outlined' : 'material-icons'"
                      style="font-size: 18px"
                      @click="passwordShow = !passwordShow"
                      >remove_red_eye</i
                    >
                  </span>
                  <br /><ErrorMessage name="パスワード" />
                </dd>
                <dt v-if="!isTransfer"><span class="required"></span>確認用</dt>
                <dd v-if="!isTransfer">
                  <Field
                    id="input_confirm"
                    v-model="formData.localConfirmPassword"
                    rules="required|confirmed:@パスワード"
                    name="確認用パスワード"
                    :type="confirmShow ? 'text' : 'password'"
                    class="sw"
                  />
                  <span class="field-icon">
                    <i
                      :class="confirmShow ? 'material-icons-outlined' : 'material-icons'"
                      style="font-size: 18px"
                      @click="confirmShow = !confirmShow"
                      >remove_red_eye</i
                    >
                  </span>
                  <br /><ErrorMessage name="確認用パスワード" />
                </dd>
                <!-- アンケートエリア -->
                <!-- eslint-disable-next-line -->
                <template v-for="ques in formData.entryQuestionnaire" :key="ques.questionId">
                  <dt :class="{ 'other-dt': ques.linkageQuestionId }">
                    <span v-if="ques.required === 1" class="required"></span>
                    <template v-if="!ques.linkageQuestionId">{{ ques.questionName }}</template>
                    <template v-if="ques.inputType === 'checkbox' && ques.required === 1">
                      <br />
                      <p class="memo">※複数選択可能</p>
                    </template>
                  </dt>
                  <dd>
                    <!-- INPUT TYPE: text-->
                    <div v-if="ques.inputType === 'text'">
                      <Field
                        v-if="ques.inputType === 'text'"
                        :id="`${ques.inputType}-${ques.questionId}`"
                        v-model="ques.answer[0]"
                        :rules="`max:100|${ques.required === 1 ? 'required' : ''}`"
                        :name="ques.questionName"
                        type="text"
                        class="input-unsubscribe"
                      />
                      <br /><ErrorMessage :name="ques.questionName" />
                    </div>
                    <!-- INPUT TYPE: radio-->
                    <div v-if="ques.inputType === 'radio'">
                      <!-- eslint-disable-next-line -->
                      <label class="container" v-for="option in ques.optionList" :key="option.key">
                        <Field
                          :id="`${ques.inputType}-${ques.questionId}-${option.key}`"
                          v-model="ques.answer[0]"
                          :rules="{ required: ques.required === 1 }"
                          type="radio"
                          :name="ques.questionName"
                          :value="option.key"
                        />
                        <span class="checkmark">{{ option.value }}</span>
                      </label>
                      <br /><ErrorMessage :name="ques.questionName" />
                    </div>
                    <!-- INPUT TYPE: pulldown-->
                    <div v-if="ques.inputType === 'pulldown'" class="max_h">
                      <Field
                        :id="`${ques.inputType}-${ques.questionId}`"
                        v-model="ques.answer[0]"
                        as="select"
                        :rules="{ required: ques.required === 1 }"
                        :name="ques.questionName"
                        class="hw"
                      >
                        <option value selected>選択してください</option>
                        <option
                          v-for="option in ques.optionList"
                          :key="option.key"
                          :value="option.key"
                        >
                          {{ option.value }}
                        </option>
                      </Field>
                      <br /><ErrorMessage :name="ques.questionName" />
                    </div>
                    <!-- INPUT TYPE: checkbox-->
                    <div v-if="ques.inputType === 'checkbox'" class="sub_enquete">
                      <!-- eslint-disable-next-line --><!-- eslint-disable vue/prefer-template -->
                      <label class="maxw" v-for="option in ques.optionList" :key="option.key">
                        <Field
                          :id="`${ques.inputType}-${ques.questionId}-${option.key}`"
                          v-model="ques.answer"
                          :rules="`${
                            ques.required === 1 ? 'required_alias:' + ques.questionName : ''
                          }`"
                          type="checkbox"
                          :name="`check-${ques.questionId}`"
                          :value="option.key"
                        />
                        <span>{{ option.value }}</span>
                      </label>
                      <br /><ErrorMessage :name="`check-${ques.questionId}`" />
                    </div>

                    <div v-if="ques.inputType === 'textarea'">
                      <p v-if="ques.linkageQuestionId" class="memo">
                        「その他」を選択した場合は、{{ ques.questionName }}を入力してください。
                      </p>
                      <Field
                        v-if="ques.inputType === 'textarea'"
                        :id="`${ques.inputType}-${ques.questionId}`"
                        v-model="ques.answer[0]"
                        as="textarea"
                        :rules="`${
                          ques.linkageQuestionId
                            ? 'other_required:@check-' + ques.linkageQuestionId + ',99|'
                            : ''
                        }max:1000|${ques.required === 1 ? 'required' : ''}`"
                        :name="ques.questionName"
                        rows="6"
                        cols="40"
                        class="maxw"
                        :disabled="isDisabled(ques.linkageQuestionId)"
                      />
                      <br /><ErrorMessage :name="ques.questionName" />
                    </div>
                  </dd>
                </template>
              </dl>
            </div>

            <div class="btn_area">
              <button type="button" class="btn_l btn_neblue" @click.stop.prevent="back">
                戻る
              </button>
              <button
                id="form-submit"
                type="button"
                :class="!meta.valid ? 'btn_l btn_gray not-allowed' : 'btn_l btn_blue'"
                :disabled="!meta.valid"
                @click.stop.prevent="next"
              >
                確認画面へ
              </button>
              <p v-show="!meta.valid" class="large_text" style="color: red">
                必須項目に未入力、もしくは不備があります。
              </p>
            </div>
          </section>
        </div>
      </main>
    </Form>
  </div>
</template>

<script>
import Common from "@/mixin/Common";
import Config from "@/conf/Config";
import { imageApi, zipcodeApi } from "@/module/Api";
import { ErrorMessage, Field, Form } from "vee-validate";

const heic2any = require("heic2any");

export default {
  name: "RegisterLayout",
  components: {
    ErrorMessage,
    Field,
    // eslint-disable-next-line vue/no-reserved-component-names
    Form,
  },
  mixins: [Common],

  data() {
    return {
      // schema: rules,
      isTransfer: this.state.isTransfer,
      otherChecked: [],
      prefecturesList: Config.PREFECTURES_LIST,
      acceptImageFormat: Config.ACCEPT_IMAGE_FORMAT,
      maxDays: 31,
      licenceMaxDays: 31,
      frontImgErros: [],
      backImgErrors: [],
      confirmationImgErrors: [],
      uploadCurrentAddressConfirmationImage: "",
      corpName: this.state.corpName,
      cleaningOrMaintenance: this.state.cleaningOrMaintenance,
      email: this.state.email,
      licenseTypes: this.state.licenseTypes,
      licenseZipcodeExistence: true,
      currentZipcodeExistence: true,
      isValidated: this.state.isValidated ? this.state.isValidated : false,
      frontImgLoading: false,
      backImgLoading: false,
      currentAddressImgLoading: false,
      disabledFlg: false,
      passwordShow: false,
      confirmShow: false,
      // nameValidateFlg: this.state.nameValidateFlg ? this.nameValidateFlg : true,
      // for input
      formData: {
        localLastName: this.state.lastName,
        localFirstName: this.state.firstName,
        locallastNameKana: this.state.lastNameKana,
        localfirstNameKana: this.state.firstNameKana,
        localGender: this.state.gender,
        localBirthYear: this.state.birthYear ? this.state.birthYear : "",
        localBirthMonth: this.state.birthMonth ? this.state.birthMonth : "",
        localBirhDate: this.state.birthDate ? this.state.birthDate : "",
        localLicenseExpirationYear: this.state.licenseExpirationYear
          ? this.state.licenseExpirationYear
          : "",
        localLicenseExpirationMonth: this.state.licenseExpirationMonth
          ? this.state.licenseExpirationMonth
          : "",
        localLicenseExpirationDate: this.state.licenseExpirationDate
          ? this.state.licenseExpirationDate
          : "",
        localLicenceNo: this.state.licenseNumber,
        localLicenseType: this.state.licenseType ? this.state.licenseType : "",
        localUploadedFrontImage: this.state.uploadedFrontImage,
        localLicenseFrontImageObjectKey: this.state.licenseFrontImageObjectKey,
        localLicenseFrontImageVersionId: this.state.licenseFrontImageVersionId,
        localUploadBackImage: this.state.uploadBackImage,
        localLicenseBackImageObjectKey: this.state.licenseBackImageObjectKey,
        localLicenseBackImageVersionId: this.state.licenseBackImageVersionId,
        localLicenseZipCode: this.state.licenseZipCode,
        localLicensePrefecturesName: this.state.licensePrefecturesName
          ? this.state.licensePrefecturesName
          : "",
        localLicenseCity: this.state.licenseCity,
        localLicenseTown: this.state.licenseTown,
        localLicenseOtherAddress: this.state.licenseOtherAddress,
        localAnotherAddress: this.state.currentAddressInputed,
        localCurrentZipCode: this.state.currentZipCode,
        localCurrentPrefecturesName: this.state.currentPrefecturesName
          ? this.state.currentPrefecturesName
          : "",
        localCurrentCity: this.state.currentCity,
        localCurrentTown: this.state.currentTown,
        localCurrentOtherAddress: this.state.currentOtherAddress,
        localUploadCurrentAddressConfirmationImage:
          this.state.uploadCurrentAddressConfirmationImage,
        localCurrentAddressConfirmationImageObjectKey:
          this.state.currentAddressConfirmationImageObjectKey,
        localCurrentAddressConfirmationImageVersionId:
          this.state.currentAddressConfirmationImageVersionId,
        localMobilePhoneNumber: this.state.mobilePhoneNumber,
        localHomePhoneNumber: this.state.homePhoneNumber,
        localEmergencyContactLastName: this.state.emergencyContactLastName,
        localEmergencyContactFirstName: this.state.emergencyContactFirstName,
        localEmergencyContactPhoneNumber: this.state.emergencyContactPhoneNumber,
        localBasicChargeExistence: this.state.basicChargeExistence,
        localUseStartYear: this.state.useStartYear,
        localUseStartMonth: this.state.useStartMonth,
        localUseStartDate: this.state.useStartDate ? this.state.useStartDate : "",
        localPassword: this.state.password,
        localConfirmPassword: this.state.confirmPassword,
        entryQuestionnaire: this.state.entryQuestionnaire,
        belongDeptName: this.state.belongDeptName,
      },
      belongDeptNameList: this.state.belongDeptNameList,
    };
  },

  computed: {
    screenId: () => "ENTR_004",
    isCorp() {
      return this.state.corpId !== 0;
    },
    maxBirthYear() {
      return new Date().getFullYear() - 17;
    },
    lastYear() {
      return new Date().getFullYear() - 1;
    },
    nowAndNextMonth() {
      const nowYear = new Date().getFullYear();
      const nowMonth = new Date().getMonth();
      const nextYear = nowMonth === 11 ? nowYear + 1 : nowYear;
      const nextMonth = nowMonth === 11 ? 1 : nowMonth + 2;
      return [
        {
          text: `${nowYear}年${nowMonth + 1}月`,
          value: new Date(nowYear, nowMonth),
        },
        {
          text: `${nextYear}年${nextMonth}月`,
          value: new Date(nowYear, nowMonth + 1),
        },
      ];
    },
    /** 会員種別に応じた料金プラン */
    targetChargePlan() {
      const type = this.getCharegePlanType(this.state.corpId, this.state.cleaningOrMaintenance);
      return this.chargePlan.chargePlanContainer.filter((c) => c.chargePlanType === type);
    },
  },

  mounted() {
    if (this.isTransfer) this.setImage();

    this.$watch(
      () => this.$refs.observer.meta.valid,
      (isValid) => {
        const isSP = window.parent.screen.width < 548;
        const toastPosition = isSP ? "top" : "top-end";
        const toastSize = isSP ? "medium_text" : "large_text";

        if (isValid && !this.wasValid) {
          // すべて OK になった場合
          this.$Swal.fire({
            position: toastPosition,
            icon: "success",
            title: "必須項目の入力が完了しました",
            showConfirmButton: false,
            toast: true,
            width: "64rem",
            background: "#e0ffff",
            customClass: toastSize,
            timer: 3000,
          });
        } else if (!isValid && this.wasValid) {
          // OK から NG に変わった場合
          this.$Swal.fire({
            position: toastPosition,
            icon: "warning",
            title: "必須項目の入力に不足があります",
            showConfirmButton: false,
            toast: true,
            width: "64rem",
            background: "#ff7f50",
            customClass: toastSize,
            timer: 3000,
          });
        }
        this.wasValid = isValid; // 現在の状態を保存
      }
    );
  },

  methods: {
    isDisabled(linkId) {
      if (!linkId) return false;
      const target = this.formData.entryQuestionnaire.find((e) => e.questionId === linkId);
      if (target) {
        if (target.answer.length > 0 && target.answer.indexOf("99") > -1) {
          return false;
        }
      }
      return true;
    },
    getImageSrc(resource) {
      if (!resource.encodeFile || !resource.contentType) {
        return "";
      }
      return `data:${resource.contentType};base64,${resource.encodeFile}`;
    },

    setImage() {
      // 画像取得（時間かかるので非同期で実施する）
      imageApi
        .getImage(
          this.state.licenseFrontImageObjectKey,
          this.state.licenseFrontImageVersionId,
          this.state.corpId,
          this.state.email
        )
        .then((result) => {
          this.formData.localUploadedFrontImage = this.getImageSrc(result);
        })
        .catch((err) => {
          this.$emit("occurError", "API", err);
        });
      this.formData.localUploadBackImage = imageApi
        .getImage(
          this.state.licenseBackImageObjectKey,
          this.state.licenseBackImageVersionId,
          this.state.corpId,
          this.state.email
        )
        .then((result) => {
          this.formData.localUploadBackImage = this.getImageSrc(result);
        })
        .catch((err) => {
          this.$emit("occurError", "API", err);
        });
      this.formData.localUploadCurrentAddressConfirmationImage = imageApi
        .getImage(
          this.state.currentAddressConfirmationImageObjectKey,
          this.state.currentAddressConfirmationImageVersionId,
          this.state.corpId,
          this.state.email
        )
        .then((result) => {
          this.formData.localUploadCurrentAddressConfirmationImage = this.getImageSrc(result);
        })
        .catch((err) => {
          this.$emit("occurError", "API", err);
        });
    },
    getMaxDays() {
      if (this.formData.localBirthYear && this.formData.localBirthMonth) {
        this.maxDays = new Date(
          this.formData.localBirthYear,
          this.formData.localBirthMonth,
          0
        ).getDate();
      }
    },
    getLicenceMaxDays() {
      if (this.formData.localLicenseExpirationYear && this.formData.localLicenseExpirationMonth) {
        this.licenceMaxDays = new Date(
          this.formData.localLicenseExpirationYear,
          this.formData.localLicenseExpirationMonth,
          0
        ).getDate();
      }
    },

    async getAddressFromZipCode(zipCode, type) {
      if (!zipCode) {
        this.$Swal.fire({
          icon: "warning",
          title: "入力エラー",
          text: "郵便番号を正しく入力してください",
        });
        return;
      }

      try {
        const zip = await zipcodeApi.getZipcode(zipCode);
        if (zip.prefecturesName) {
          if (type === "license") {
            this.licenseZipcodeExistence = true;
            this.formData.localLicensePrefecturesName = zip.prefecturesName;
            this.formData.localLicenseCity = zip.city;
            this.formData.localLicenseTown = zip.town;
          } else if (type === "current") {
            this.currentZipcodeExistence = true;
            this.formData.localCurrentPrefecturesName = zip.prefecturesName;
            this.formData.localCurrentCity = zip.city;
            this.formData.localCurrentTown = zip.town;
          }
        } else if (type === "license") {
          this.licenseZipcodeExistence = false;
          this.formData.localLicensePrefecturesName = "";
          this.formData.localLicenseCity = "";
          this.formData.localLicenseTown = "";
        } else if (type === "current") {
          this.currentZipcodeExistence = false;
          this.formData.localCurrentPrefecturesName = "";
          this.formData.localCurrentCity = "";
          this.formData.localCurrentTown = "";
        }
      } catch (error) {
        this.$emit("occurError", "API", error);
      }
    },

    // nameValidation() {
    //   // 本人 or 緊急連絡先のいずれかに入力がある時のみ
    //   if (
    //     !isEmpty(this.formData.localLastName) ||
    //     !isEmpty(this.formData.localFirstName) ||
    //     !isEmpty(this.formData.localEmergencyContactLastName) ||
    //     !isEmpty(this.formData.localEmergencyContactFirstName)
    //   ) {
    //     if (
    //       `${this.formData.localLastName}${this.formData.localFirstName}` ===
    //       `${this.formData.localEmergencyContactLastName}${this.formData.localEmergencyContactFirstName}`
    //     ) {
    //       this.nameValidateFlg = false;
    //     } else {
    //       this.nameValidateFlg = true;
    //     }
    //   }
    // },

    async postFileToS3(file) {
      const params = new FormData();
      params.append("uploadFile", file);
      try {
        return await imageApi.uploadImage(params);
      } catch (error) {
        this.$emit("occurError", "API", error);
        this.disabledFlg = false;
        return "";
      }
    },

    fileValidation(file) {
      const erros = [];
      // フォーマットチェック
      if (file.type !== "image/jpeg" && file.type !== "image/jpg" && file.type !== "image/png") {
        // heicでもない
        const nameArray = file.name.split(".");
        if (nameArray.slice(-1)[0] !== "HEIC" && nameArray.slice(-1)[0] !== "heic") {
          erros.push("使用できる形式は「jpeg/jpg/png/HEIC」のいずれかです");
        }
      }
      // サイズチェック
      if (file.size > 52428800) {
        erros.push("使用できるサイズは50MBまでです");
      }

      return erros;
    },

    async convertHeic(blob) {
      const convertedBlod = await heic2any({
        blob,
        toType: "image/jpeg",
        quality: 0.5,
      });
      return convertedBlod;
    },

    onUseStartDateChange() {
      if (this.formData.localUseStartDate) {
        const selectDate = this.formData.localUseStartDate;
        this.formData.localUseStartYear = selectDate.getFullYear();
        this.formData.localUseStartMonth = selectDate.getMonth() + 1;
      } else {
        this.formData.localUseStartYear = "";
        this.formData.localUseStartMonth = "";
      }
    },

    async onFrontFileChange(e) {
      let blob = e.target.files[0];
      if (!blob) return;
      this.disabledFlg = true;
      // 上書きの場合、まずクリア
      if (this.formData.localUploadedFrontImage) {
        this.formData.localUploadedFrontImage = undefined;
        this.formData.localLicenseFrontImageObjectKey = undefined;
        this.formData.localLicenseFrontImageVersionId = undefined;
      }
      const errors = this.fileValidation(blob);
      if (errors.length) {
        this.frontImgErros = errors;
        this.formData.localUploadedFrontImage = undefined;
        this.formData.localLicenseFrontImageObjectKey = undefined;
        this.formData.localLicenseFrontImageVersionId = undefined;
        this.disabledFlg = false;
      } else {
        this.frontImgErros = [];
        this.frontImgLoading = true;
        // heicの場合はjpegに変換する
        if (!blob.type || blob.type === "image/heic") {
          blob = await this.convertHeic(blob);
        }
        const reader = new FileReader();
        reader.onload = (load) => {
          this.formData.localUploadedFrontImage = load.target.result;
        };
        reader.readAsDataURL(blob);
        this.frontImgLoading = false;
        // S3にアップロード
        const resData = await this.postFileToS3(blob);
        this.formData.localLicenseFrontImageObjectKey = resData.fileObjectKey;
        this.formData.localLicenseFrontImageVersionId = resData.versionId;
        this.disabledFlg = false;
      }
    },

    async onBackFileChange(e) {
      let blob = e.target.files[0];
      if (!blob) return;
      this.disabledFlg = true;
      // 上書きの場合、まずクリア
      if (this.formData.localUploadBackImage) {
        this.formData.localUploadBackImage = undefined;
        this.formData.localLicenseBackImageObjectKey = undefined;
        this.formData.localLicenseBackImageVersionId = undefined;
      }
      const errors = this.fileValidation(blob);
      if (errors.length) {
        this.backImgErrors = errors;
        this.formData.localUploadBackImage = undefined;
        this.formData.localLicenseBackImageObjectKey = undefined;
        this.formData.localLicenseBackImageVersionId = undefined;
        this.disabledFlg = false;
      } else {
        this.backImgErrors = [];
        this.backImgLoading = true;
        // heicの場合はjpegに変換する
        if (!blob.type || blob.type === "image/heic") {
          blob = await this.convertHeic(blob);
        }
        const reader = new FileReader();
        reader.onload = (load) => {
          this.formData.localUploadBackImage = load.target.result;
        };
        reader.readAsDataURL(blob);
        this.backImgLoading = false;
        // S3にアップロード
        const resData = await this.postFileToS3(blob);
        this.formData.localLicenseBackImageObjectKey = resData.fileObjectKey;
        this.formData.localLicenseBackImageVersionId = resData.versionId;
        this.disabledFlg = false;
      }
    },

    async onCurrentAddressConfirmationFileChange(e) {
      let blob = e.target.files[0];
      if (!blob) return;
      this.disabledFlg = true;
      // 上書きの場合、まずクリア
      if (this.formData.localUploadCurrentAddressConfirmationImage) {
        this.formData.localUploadCurrentAddressConfirmationImage = undefined;
        this.formData.localCurrentAddressConfirmationImageObjectKey = undefined;
        this.formData.localCurrentAddressConfirmationImageVersionId = undefined;
      }
      const errors = this.fileValidation(blob);
      if (errors.length) {
        this.confirmationImgErrors = errors;
        this.formData.localUploadCurrentAddressConfirmationImage = undefined;
        this.formData.localCurrentAddressConfirmationImageObjectKey = undefined;
        this.formData.localCurrentAddressConfirmationImageVersionId = undefined;
        this.disabledFlg = false;
      } else {
        this.confirmationImgErrors = [];
        this.currentAddressImgLoading = true;
        // heicの場合はjpegに変換する
        if (!blob.type || blob.type === "image/heic") {
          blob = await this.convertHeic(blob);
        }
        const reader = new FileReader();
        reader.onload = (load) => {
          this.formData.localUploadCurrentAddressConfirmationImage = load.target.result;
        };
        reader.readAsDataURL(blob);
        this.currentAddressImgLoading = false;
        // S3にアップロード
        const resData = await this.postFileToS3(blob);
        this.formData.localCurrentAddressConfirmationImageObjectKey = resData.fileObjectKey;
        this.formData.localCurrentAddressConfirmationImageVersionId = resData.versionId;
        this.disabledFlg = false;
      }
    },

    commit() {
      this.$emit("commit", {
        ...this.localstate,
        isTransfer: this.isTransfer,
        isValidated: this.isValidated,
        lastName: this.formData.localLastName,
        firstName: this.formData.localFirstName,
        lastNameKana: this.formData.locallastNameKana,
        firstNameKana: this.formData.localfirstNameKana,
        gender: this.formData.localGender,
        birthYear: this.formData.localBirthYear,
        birthMonth: this.formData.localBirthMonth,
        birthDate: this.formData.localBirhDate,
        licenseExpirationYear: this.formData.localLicenseExpirationYear,
        licenseExpirationMonth: this.formData.localLicenseExpirationMonth,
        licenseExpirationDate: this.formData.localLicenseExpirationDate,
        licenseNumber: this.formData.localLicenceNo,
        licenseType: this.formData.localLicenseType,
        uploadedFrontImage: this.formData.localUploadedFrontImage,
        licenseFrontImageObjectKey: this.formData.localLicenseFrontImageObjectKey,
        licenseFrontImageVersionId: this.formData.localLicenseFrontImageVersionId,
        uploadBackImage: this.formData.localUploadBackImage,
        licenseBackImageObjectKey: this.formData.localLicenseBackImageObjectKey,
        licenseBackImageVersionId: this.formData.localLicenseBackImageVersionId,
        licenseZipCode: this.formData.localLicenseZipCode,
        licensePrefecturesName: this.formData.localLicensePrefecturesName,
        licenseCity: this.formData.localLicenseCity,
        licenseTown: this.formData.localLicenseTown,
        licenseOtherAddress: this.formData.localLicenseOtherAddress,
        currentAddressInputed: this.formData.localAnotherAddress,
        currentZipCode: this.formData.localCurrentZipCode,
        currentPrefecturesName: this.formData.localCurrentPrefecturesName,
        currentCity: this.formData.localCurrentCity,
        currentTown: this.formData.localCurrentTown,
        currentOtherAddress: this.formData.localCurrentOtherAddress,
        uploadCurrentAddressConfirmationImage:
          this.formData.localUploadCurrentAddressConfirmationImage,
        currentAddressConfirmationImageObjectKey:
          this.formData.localCurrentAddressConfirmationImageObjectKey,
        currentAddressConfirmationImageVersionId:
          this.formData.localCurrentAddressConfirmationImageVersionId,
        mobilePhoneNumber: this.formData.localMobilePhoneNumber,
        homePhoneNumber: this.formData.localHomePhoneNumber,
        emergencyContactLastName: this.formData.localEmergencyContactLastName,
        emergencyContactFirstName: this.formData.localEmergencyContactFirstName,
        emergencyContactPhoneNumber: this.formData.localEmergencyContactPhoneNumber,
        basicChargeExistence: this.formData.localBasicChargeExistence,
        useStartYear:
          this.formData.localBasicChargeExistence === 0
            ? new Date().getFullYear()
            : this.formData.localUseStartYear,
        useStartMonth:
          this.formData.localBasicChargeExistence === 0
            ? new Date().getMonth() + 1
            : this.formData.localUseStartMonth,
        useStartDate: this.formData.localUseStartDate,
        password: this.formData.localPassword,
        confirmPassword: this.formData.localConfirmPassword,
        entryQuestionnaire: this.formData.entryQuestionnaire,
        chargePlanName:
          this.targetChargePlan.length === 1
            ? this.targetChargePlan[0].chargePlanName
            : this.targetChargePlan.filter(
                (t) => t.basicChargeExistence === this.formData.localBasicChargeExistence
              )[0].chargePlanName,
        belongDeptName: this.formData.belongDeptName,
      });
    },
    back() {
      this.$emit("changeStep", "steps step_2");
      this.commit();
    },
    async next() {
      const isValid = await this.$refs.observer.validate();
      // this.nameValidation();
      if (!isValid) {
        this.$Swal.fire({
          icon: "warning",
          title: "入力項目に不足、または不備があります",
          customClass: "large_text",
          text: "必須項目の記入漏れやエラーメッセージをご確認ください",
        });
      } else if (
        !this.formData.localLicenseFrontImageObjectKey ||
        !this.formData.localLicenseFrontImageVersionId ||
        !this.formData.localLicenseBackImageObjectKey ||
        !this.formData.localLicenseBackImageVersionId ||
        (this.formData.localAnotherAddress &&
          (!this.formData.localCurrentAddressConfirmationImageObjectKey ||
            !this.formData.localCurrentAddressConfirmationImageVersionId))
      ) {
        this.$Swal.fire({
          icon: "warning",
          title: "画像の登録処理をしています",
          text: "登録が完了するまでお待ち下さい",
        });
      } else {
        this.commit();
        // 入力内容確認コンポーネントに切り替え
        this.$emit("changeStep", "steps step_4");
        document.cookie = `${Config.FUNCTION_ID}steps step_4; path=/`;
        window.scroll(0, 0);
      }
    },
  },
};
</script>
