<template>
  <div>
    <form>
      <main class="cf">
        <div class="container">
          <section class="common_section section_terms">
            <h2>規約の確認</h2>
            <div class="box cf">
              <div class="external_links">
                <!-- eslint-disable-next-line -->
                <label>
                  <!-- 貸渡約款 -->
                  <a
                    :href="corpParam.agreementUrl"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="external_link"
                    >{{ webContents.article.agreementLabel }}</a
                  >
                  <!-- プライバシーポリシー -->
                  <a
                    :href="corpParam.privacyPolicyUrl"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="external_link"
                    >{{ webContents.article.privacyPolicyLabel }}</a
                  >
                </label>
              </div>
              <p v-html="webContents.article.confirmTerms"></p>
              <div class="check_terms">
                <label>
                  <input id="checkTerms" v-model="check" type="checkbox" />
                  <!-- 貸渡約款およびプライバシーポリシーについて同意します。 -->
                  <span v-html="webContents.article.agreementInfo"></span>
                </label>
              </div>
            </div>
          </section>

          <div class="btn_area">
            <button
              id="submitButton"
              type="button"
              :class="buttonClass"
              :disabled="!check"
              @click.stop.prevent="authentication"
            >
              次へ
            </button>
          </div>
        </div>
      </main>
    </form>
  </div>
</template>

<script>
import queryString from "query-string";
import extractCodebyCodeKey from "@/helper/code";
import Common from "@/mixin/Common";
import Config from "@/conf/Config";
import ErrorTracking from "@/module/ErrorTracking";
import moment from "moment";
import {
  codeApi,
  corporationApi,
  commonApi,
  loginApi,
  auth,
  sessionApi,
  questionApi,
} from "@/module/Api";

const MSG = {
  MEMBER: `お申し込みのアカウントは以下のカーシェアサービスで登録されております。
            <br>登録済みのパスワードでログインしてください。
            <br>`,
  CORP_MEMBER: `お申し込みのアカウントは以下のカーシェアリングサービスで会員登録済みのため、会員情報の一部を引き継ぐことができます。
                <br/>会員情報を引き継ぐ場合は、登録済みのパスワードでログインしてください。
                <br/>会員情報を引き継がない場合は、別のメールアドレスで招待メールの再送信をご依頼ください。<br/>`,
};

export default {
  name: "IndexLayout",
  mixins: [Common],
  data() {
    return {
      subCode: "",
      ids: "",
      check: false,
      isTransfer: false,
      session: undefined,
    };
  },
  computed: {
    screenId: () => "ENTR_003",
    buttonClass() {
      return this.check ? "btn_l btn_blue" : "btn_l btn_gray not-allowed";
    },
  },
  async mounted() {
    const param = queryString.parse(window.location.search);
    this.subCode = param.subCode;
    this.ids = param.ids;
    if (!this.ids) {
      // URI不正
      this.$emit("occurError", "GENERAL", new URIError(`request uri is ${window.location.href}`));
    }

    if (!this.subCode) {
      this.isTransfer = true;
    }
  },
  methods: {
    async authentication() {
      const { userName, corpId } = JSON.parse(window.atob(this.ids));

      if (this.subCode) {
        this.nextStep();
      } else {
        // 流用登録はeemo認証を挟む
        const serviceList = await commonApi.getServiceList(userName);
        const message = corpId === 0 ? MSG.MEMBER : MSG.CORP_MEMBER;
        this.$Swal.fire({
          title: "",
          html: `
          <div class="conf-msg">
            <div class="swal-info">
            ${message}
            ${this.serviceLogo(serviceList)}
            </div>
            <label>ログインID</label><div class="item2">${userName}</div>
            <label>パスワード</label><div class="item3"><input id="swal-password" type="password" class="swal2-input" value=""></div>
          </div>
          `,
          focusConfirm: false,
          allowOutsideClick: () => !this.$Swal.isLoading(),
          showLoaderOnConfirm: true,
          confirmButtonText: "認証する",
          customClass: {
            popup: "login-popup",
          },
          preConfirm: async () => {
            const password = [document.getElementById("swal-password").value];
            return loginApi
              .login(userName, password)
              .then(async (result) => {
                localStorage.setItem(
                  Config.SESSION_PARAM.REFRESH_TOKEN,
                  result.getRefreshToken().getToken()
                );

                const cognitoUser = auth.getCurrentUser();
                localStorage.setItem(Config.SESSION_PARAM.COGNITO_ID, cognitoUser.username);
                this.subCode = auth.getCurrentUser().username;
                await this.nextStep();
              })
              .catch((err) => {
                if (err.code === "maintenance") {
                  this.$emit("occurError", "API", err);
                } else if (err.code === "NotAuthorizedException") {
                  this.$Swal.showValidationMessage(
                    `認証に失敗しました。ユーザー名またはパスワードが間違っています。`
                  );
                } else if (err.code === "invalidParameter") {
                  // 既に登録済みエラー
                  this.$emit("viewAssumedError", "registered");
                } else {
                  // 予期せぬエラー
                  this.$emit(
                    "occurError",
                    "GENERAL",
                    new Error(`Unexpected error in Cognito authentication. ids is ${this.ids}.`)
                  );
                }
              });
          },
        });
      }
    },
    async nextStep() {
      const { userName, corpId, carShareCorpId } = JSON.parse(window.atob(this.ids));
      if (userName != null && corpId != null && carShareCorpId != null) {
        let corpName;
        let cleaningOrMaintenance;
        let belongDeptNameList;
        if (corpId !== 0) {
          const corporation = await corporationApi.getCorpEntryInfo(corpId);
          corpName = corporation.corpName;
          cleaningOrMaintenance = corporation.cleaningOrMaintenance;
          const ret = await commonApi.getBelongDeptName(corpId);
          belongDeptNameList = ret.valueList;
        }

        if (this.isTransfer) {
          this.session = await sessionApi.getSession(this.subCode);
          localStorage.setItem(Config.SESSION_PARAM.MEMBER_ID, this.session.memberId);
          ErrorTracking.configureScope(this.session);
        }

        // 会員の法人種別に紐づく基本料金種別を設定
        const type = this.getCharegePlanType(corpId, cleaningOrMaintenance);
        const { basicChargeExistence } = this.chargePlan.chargePlanContainer.find(
          (c) => c.chargePlanType === type
        );

        try {
          // 汎用コード取得
          const genralCodes = await codeApi.getGeneralCodes("license_type");
          if (!genralCodes) return;
          const licenseTypes = extractCodebyCodeKey(genralCodes, "license_type");

          // 入会アンケート取得
          const entryQuestionnaire = await questionApi.getQuestion();

          let dayParam = {};
          if (auth.isAuth()) {
            dayParam = {
              birthYear: moment(this.session.birthday).year(),
              birthMonth: moment(this.session.birthday).month() + 1,
              birthDate: moment(this.session.birthday).date(),
              licenseExpirationYear: moment(this.session.licenseExpirationDate).year(),
              licenseExpirationMonth: moment(this.session.licenseExpirationDate).month() + 1,
              licenseExpirationDate: moment(this.session.licenseExpirationDate).date(),
            };
            this.showSuccess();
          }

          this.$emit("commit", {
            subCode: this.subCode,
            email: userName,
            corpId,
            belongDeptNameList,
            corpName,
            cleaningOrMaintenance,
            carShareCorpId,
            licenseTypes,
            basicChargeExistence,
            isTransfer: this.isTransfer,
            entryQuestionnaire,
            ...this.session,
            ...dayParam,
          });
          // 会員情報登録コンポーネントに切り替え
          this.$emit("changeStep", "steps step_3");
          document.cookie = `${Config.FUNCTION_ID}steps step_3; path=/`;
          window.scroll(0, 0);
        } catch {
          window.onbeforeunload = null; // 離脱確認除去
          this.$Swal.fire({
            icon: "warning",
            title: "通信エラーが発生しました",
            text: `お手数ですが、画面を再表示してお試しください。`,
            customClass: "medium_text",
            grow: "row",
            confirmButtonText: "再読み込み",
            preConfirm: async () => {
              window.location.reload();
            },
          });
        }
      } else {
        // 改竄されてる
        this.$emit(
          "occurError",
          "GENERAL",
          new Error(`request parameter is falsified. ids is ${this.ids}.`)
        );
      }
    },
    serviceLogo(serviceList) {
      let nameList = "";
      serviceList.forEach((s) => {
        nameList += `
        <li class="exchange_item" >
        <image src="${s.appImageUrl}" alt="${s.serviceName}"></image>
        <span>${s.serviceName}</span>
        </li>
        `;
      });
      return `<ul>${nameList}</ul>`;
    },
    /** loading dialog */
    showLoading() {
      this.$Swal.fire({
        title: "処理中",
        html: "画面はそのままにしてください。",
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          this.$Swal.showLoading();
        },
      });
    },
    async showSuccess() {
      await this.$Swal.fire({
        position: "center",
        icon: "success",
        title: "認証に成功しました",
        background: "#e0ffff",
        showConfirmButton: false,
        timer: 1500,
      });
    },
  },
};
</script>
<style scoped></style>
