<template>
  <div>
    <Form v-slot="{ meta }" ref="observer" onsubmit="return false;">
      <main class="cf">
        <div class="container">
          <section class="common_section section_message">
            <div class="box cf">
              <h3>入会申し込みを受け付けました。</h3>
              <p v-html="webContents.article.entryCompInfo1"></p>
              <br />
              <span class="tel"
                >対応窓口電話番号：{{ corpParam.examinationRequestDestinationPhoneNumber }}</span
              >
              <br />
              <p v-html="webContents.article.entryCompInfo2"></p>
            </div>
          </section>

          <section class="common_section basic_info">
            <h2>クレジットカード情報入力</h2>
            <p class="credit_memo" v-html="webContents.article.cardInfo"></p>
            <div class="box">
              <dl class="info_detail">
                <dt><span class="required"></span>クレジットカード番号</dt>
                <dd>
                  <Field
                    id="input_card_number"
                    v-model="creditCardNumber"
                    rules="required|only_half_number|min:14|max:16"
                    name="クレジットカード番号"
                    type="text"
                    placeholder="例：1234567890123456"
                    class="mw"
                  />
                  <br />
                  <ErrorMessage name="クレジットカード番号" />
                  <!-- <p
                      style="color: red"
                      id="card_number_error"
                      v-if="Object.keys(failedRules)[0] !== 'required'"
                    >
                      {{ errors[0] }}
                    </p>
                  </ValidationProvider> -->
                  <p class="memo">
                    ※カード番号は続けてご入力ください。（半角数字・スペース・ハイフンなし）
                    <br />※デビットカード及びブリペイドカードはご登録できません。
                    <br />※登録可能なクレジットカードは以下の通りです。
                    <br />※安全にクレジットカード決済をご利用いただくため、本人認証サービス（3Dセキュア2.0）を導入しています。
                  </p>
                  <img
                    :src="webContents.images.creditCard"
                    :srcset="`${webContents.images.creditCard} 1x, ${webContents.images.creditCard2x} 2x`"
                    alt="creditCard"
                    width="270"
                    height="33"
                  />
                </dd>
                <dt>
                  <span class="required"></span>有効期限
                  <span class="comment">※西暦は下2桁</span>
                </dt>
                <dd>
                  <Field
                    id="select_expiration_month"
                    v-model="expirationMonth"
                    rules="required"
                    name="有効期限(月)"
                    as="select"
                  >
                    <option value>選択</option>
                    <option v-for="n in 12" :key="n" :value="n">{{ n }}</option>
                  </Field>
                  <!-- </ValidationProvider> -->
                  <span class="name_adj">月 /</span>
                  <Field
                    id="select_expiration_year"
                    v-model="expirationYear"
                    rules="required"
                    name="有効期限(年)"
                    as="select"
                  >
                    <option value>選択</option>
                    <option v-for="n in 10" :key="baseYear + n" :value="baseYear + n">
                      {{ baseYear + n }}
                    </option>
                  </Field>
                  <!-- </ValidationProvider> -->
                  <span class="name_adj">年</span>
                </dd>
                <dt><span class="required"></span>カード名義人</dt>
                <dd>
                  <Field
                    id="input_card_name"
                    v-model="creditCardName"
                    rules="card_name|required"
                    name="カード名義人"
                    type="text"
                    placeholder="例：TARO SUZUKI"
                    class="mw"
                  />
                  <br />
                  <ErrorMessage name="カード名義人" />
                  <!-- <p style="color: red" v-if="Object.keys(failedRules)[0] !== 'required'">
                      {{ errors[0] }}
                    </p>
                  </ValidationProvider> -->
                  <p class="memo">※ご本人名義のクレジットカードをご登録ください。</p>
                </dd>
                <dt><span class="required"></span>セキュリティコード</dt>
                <dd>
                  <Field
                    id="input_security_code"
                    v-model="securityCode"
                    rules="required|only_half_number|min:3|max:4"
                    name="セキュリティコード"
                    type="text"
                    placeholder="例：000"
                    class="xsw"
                  />
                  <br />
                  <ErrorMessage name="セキュリティコード" />
                  <!-- <p
                      style="color: red"
                      id="card_name_error"
                      v-if="Object.keys(failedRules)[0] !== 'required'"
                    >
                      {{ errors[0] }}
                    </p>
                  </ValidationProvider> -->
                  <p class="memo" v-html="webContents.article.secCodeInfo"></p>
                </dd>
              </dl>
              <div class="btn_area">
                <button
                  id="regist_btn"
                  type="button"
                  :class="!meta.valid ? 'btn_l btn_gray not-allowed' : 'btn_l btn_blue'"
                  :style="wait"
                  :disabled="!meta.valid || disabledFlg"
                  @click.stop.prevent="cardRegister"
                >
                  クレジットカード情報登録完了
                </button>
                <p v-show="!meta.valid" class="large_text" style="color: red">
                  登録に必要な情報を入力してください。
                </p>
              </div>
            </div>
          </section>
        </div>
      </main>
    </Form>
    <!-- </form>
    </ValidationObserver> -->
  </div>
</template>

<script>
import Common from "@/mixin/Common";
import { cardApi } from "@/module/Api";
import { ErrorMessage, Field, Form } from "vee-validate";

export default {
  name: "CardLayout",
  components: {
    ErrorMessage,
    Field,
    // eslint-disable-next-line vue/no-reserved-component-names
    Form,
  },
  mixins: [Common],
  data() {
    return {
      creditCardNumber: "",
      expirationYear: "",
      expirationMonth: "",
      creditCardName: "",
      securityCode: "",
      disabledFlg: false,
      wait: "",
    };
  },

  computed: {
    screenId: () => "ENTR_006",
    baseYear() {
      const lastYear = new Date().getFullYear() - 1;
      const endTwoDigit = String(lastYear).substring(2, 4);
      return Number(endTwoDigit);
    },
  },

  methods: {
    cardRegister() {
      // eslint-disable-next-line
      const paygentData = PAYGENT_DATA || {};
      // 二重submit 防止 && ポインタ通信中
      this.disabledFlg = true;
      this.wait = "cursor: wait;";
      // eslint-disable-next-line
      const paygentToken = new PaygentToken();
      // トークン生成メソッドの実行
      paygentToken.createToken(
        paygentData.marchantId, // マーチャントID
        paygentData.tokenCreateKey, // トークン生成鍵
        {
          card_number: this.creditCardNumber, // カード番号
          expire_year: this.expirationYear, // 有効期限-YY
          expire_month: this.expirationMonth, // 有効期限-MM
          cvc: this.securityCode, // セキュリティコード
          name: this.creditCardName, // カード名義
        },
        this.callPaygentApi // コールバック
      );
    },
    async callPaygentApi(response) {
      if (response.result === "0000") {
        try {
          // クレジットカード登録APIコール
          await cardApi.entry({
            subCode: this.state.subCode,
            cardToken: response.tokenizedCardObject.token,
          });
          // 登録完了画面へ切り替え
          this.$emit("changeStep", "steps step_6");
          window.scroll(0, 0);
        } catch (e) {
          // メンテナンスモード
          if (e.response.data.code === "maintenance") {
            this.$emit("occurError", "API", e);
          }

          if (e.code === "DebitPrepaidCardFailure") {
            this.$Swal.fire({
              icon: "warning",
              title: "クレジットカード情報の登録に失敗しました。",
              html: e.response.data.message,
              customClass: "large_text",
            });
          } else {
            this.$Swal.fire({
              icon: "warning",
              title: "クレジットカード情報の登録に失敗しました。",
              html: "主な原因として以下が考えられます。<br>・ご利用枠が上限に達している<br>・カード情報の入力に誤りがある<br>お手数ですがご確認の上、再度お試しください。",
              customClass: "medium_text",
            });
          }
          this.disabledFlg = false;
          this.wait = "";
        }
      } else if (response.result === "1502") {
        this.$Swal.fire({
          icon: "warning",
          title: "有効期限(年月)が不正です。",
          text: "ご確認の上、再度入力ください。",
          customClass: "medium_text",
        });
        this.disabledFlg = false;
        this.wait = "";
      } else if (response.result === "7000") {
        this.$Swal.fire({
          icon: "warning",
          title: "非対応のブラウザ",
          html: "ご使用のブラウザではカード登録ができません。<br>お手数ですがスマホアプリからのご登録をお願いします。",
          customClass: "medium_text",
        });
        this.disabledFlg = false;
        this.wait = "";
      } else {
        this.$emit("occurError", "GENERAL");
      }
    },
  },
};
</script>
