import { createApp } from "vue";
import { defineRule, configure } from "vee-validate";
// eslint-disable-next-line
import {
  required,
  max,
  numeric,
  digits,
  regex,
  min,
  confirmed,
  mimes,
  max_value,
  min_value,
} from "@vee-validate/rules";

// eslint-disable-next-line
import SimpleTypeahead from "vue3-simple-typeahead";
// eslint-disable-next-line
import "vue3-simple-typeahead/dist/vue3-simple-typeahead.css";

// for event logging
import * as Sentry from "@sentry/browser";
// import { Vue as VueIntegration } from "@sentry/integrations";
import * as Integrations from "@sentry/integrations";

// add plugin for global
import MyApplicationPlugin from "@/module/plugin/application";

import App from "./App.vue";
import "./assets/css/main.css";

// カスタムメッセージの設定
configure({
  generateMessage: (context) => {
    const { field, rule } = context;
    const messages = {
      required: `${field}は必須です`,
      max: `${field}は${rule.params[0]}文字以内で入力してください`,
      min: `${field}は${rule.params[0]}文字以上で入力してください`,
      numeric: `${field}は半角数字のみで入力してください`,
      digits: `${field}は${rule.params[0]}桁の半角数字で入力してください`,
      required_if: `${field}は必須項目です`,
      regex: `${field}の書式が正しくありません`,
      confirmed: `${field}が一致しません`,
      mimes: `${field}は有効なファイル形式ではありません`,
      max_value: `${field}は${rule.params[0]}以下で入力してください`,
      min_value: `${field}は${rule.params[0]}以上で入力してください`,
      other_required: `「その他」を選択している場合、${field}は必須項目です`,
      katakana: `${field}はカタカナで入力してください`,
      confirm_phone_number: "ご自身の連絡先とは別の連絡先を入力してください",
      phone_number_digits: `${field}は10桁、もしくは11桁の半角数字で入力してください`,
      password: `パスワードの要件を満たしていません`,
      only_half_number: `${field}は半角数字で入力してください`,
      card_name: `カード名義人は大文字アルファベットまたは半角スペースのみで入力してください`,
    };
    return messages[context.rule.name] || "バリデーションエラー";
  },
});
defineRule("required", required);
defineRule("max", max);
defineRule("min", min);
defineRule("numeric", numeric);
defineRule("digits", digits);
defineRule("regex", regex);
defineRule("confirmed", confirmed);
defineRule("mimes", mimes);
defineRule("max_value", max_value);
defineRule("min_value", min_value);

/* カスタムルール */
// エラーメッセージに別名を使用する。（事業者アンケートのname属性にはその他項目とのリンクに使用するため）
defineRule("required_alias", (value, [alias]) => {
  if (!required(value)) {
    return `${alias}は必須項目です`;
  }
  return true;
});
defineRule("required_if", (value, [targetField, targetValue], ctx) => {
  // targetField の値を取得
  const target = ctx.form[targetField];
  // target が targetValue と等しい場合、value が空でないことを要求
  if (target === targetValue && !value) {
    return false;
  }
  // 条件を満たさない場合は検証をスキップ
  return true;
});
defineRule("other_required", (value, [answer, other]) => {
  // const target = ctx.form[answer];
  if (answer && answer.indexOf(other) > -1) {
    return value ? value.trim() !== "" : false;
  }
  return true;
});
defineRule("differentFullName", (value, [lastName, firstName, emergencyLastName]) => {
  const fullName = lastName + firstName;
  const emergencyFullName = emergencyLastName + value;
  // 氏名と緊急連絡先氏名が一致するか確認
  if (fullName === emergencyFullName) {
    return "ご自身の連絡先とは別の連絡先を入力してください";
  }
  return true;
});
defineRule("katakana", (value) => /^[ァ-ヶｦ-ﾟー]*$/.test(value));
defineRule("phone_number_digits", (value) => !value || value.length === 10 || value.length === 11);
defineRule("confirm_phone_number", (value, [mobile]) => value !== mobile);
defineRule("password", (value) =>
  /^(?=.*[a-z])(?=.*?\d)(?=.*[A-Z])[a-zA-Z\d@$!%*?&]*$/.test(value)
);
defineRule("only_half_number", (value) => /^[0-9]+$/.test(value));
defineRule("card_name", (value) => /^[A-Z ]+$/.test(value));

const app = createApp(App);
app.use(MyApplicationPlugin);
app.use(SimpleTypeahead);

Sentry.init({
  app,
  // eslint-disable-next-line
  dsn: SENTRY_ENDPOINT || "https://6a582feb70e4497985cfe8d12405b86d@sentry.io/1889717",
  integrations: [
    new Integrations.Vue({
      attachProps: true,
    }),
  ],
  // トレースのサンプルレート
  tracesSampleRate: 1.0,
});

// datadog廃止
global.DD_LOGS = {
  logger: {
    info: (message) => console.log(message),
  },
  init: () => {},
  addLoggerGlobalContext: () => {},
};

// eslint-disable-next-line
DD_LOGS.init({
  // eslint-disable-next-line
  clientToken: DD_DOGS_TOKNEN || "pubd4810b97d83909ccb893b00bfc6a330a",
  forwardErrorsToLogs: true,
});
// eslint-disable-next-line
DD_LOGS.addLoggerGlobalContext("AppType", "front-web");

app.mount("#app");
